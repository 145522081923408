<template>
  <v-dialog
      scrollable
      transition="dialog-top-transition"
      v-model="dialog"
      max-width="800"
      persistent
  >
    <v-card :disabled="onlyRead" tile>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <div><strong>Будущее имя файла:</strong> {{ fileName }}</div>
          <br>

          <v-text-field label="Access Token" v-model="accessToken" :rules="[rules.required, rules.tgToken]"/>
          <v-row>
            <v-col>
              <v-text-field label="Ваше названия бота" v-model="botName" :disabled="!!botEditId"
                            :rules="[rules.onlyLatinAndNumbers1, rules.min3]"/>
            </v-col>
            <v-col>
              <v-text-field label="BotUsername (для рассылки)" v-model="botUsername" prefix="@"
                            :rules="[rules.onlyLatinAndNumbers2, rules.min3]"/>
            </v-col>
            <v-col>
              <v-text-field label="Уникальный идентификатор проекта" v-model="uniqueName" :disabled="!!botEditId"
                            :rules="[rules.onlyLatinAndNumbers1, rules.min3]"/>
            </v-col>
          </v-row>

          <UploadSurveyLogicComponent/>

          <v-row>
            <v-col cols="6">
              <v-select
                  v-model="easyCommunicationMode"
                  :items=" [
                    { text: 'Обращение на ТЫ', value: true },
                    { text: 'Обращение на ВЫ', value: false }
                  ]"
              />
            </v-col>
          </v-row>

          <v-checkbox label="Без персональной ссылки - не общаться" v-model="denyUsersWithoutUniqueRef"/>
          <div class="mb-5" v-if="denyUsersWithoutUniqueRef">{{ refLink }}</div>

          <v-select label="Survey ending" v-model="survEnd" :items="surveyEndingItems"/>
          <v-checkbox
              label="Настройки для опроса-дневника (в случае заполнения респондентом несколько раз)"
              v-model="blockRepeat"
          />
          <v-card v-if="blockRepeat">
            <v-card-text>
              <div>Установите минимальное и максимальное кол-во часов, которое пользователь может отложить беседу</div>
              <v-row>
                <v-col>
                  <v-text-field label="Минимальное часов в /set" v-model="timerLimitsMin" type="number"
                                :rules="[rules.min01, rules.maxMoreMin]"/>
                </v-col>
                <v-col>
                  <v-text-field label="Максимальное часов в /set" v-model="timerLimitsMax" type="number"
                                :rules="[rules.maxMoreMin]"/>
                </v-col>
              </v-row>
              <v-checkbox label="User не может запустить опрос (нажатие /poll), если им уже выставлен таймер"
                          v-model="denyPollDuringTimer"/>
              <v-row>
                <v-col class="col-6">
                  <v-text-field label="Сколько раз в цикле нужно спросить user" v-model="maxAmountOfFinishedPolls"
                                type="number"/>
                </v-col>
              </v-row>
              <v-row v-if="surveyLogicSrc === 'qma'">
                <v-col>
                  <v-autocomplete
                      label="Начальный вопрос цикла"
                      :items="questions"
                      :rules="[rules.required, rules.onlyLatinAndNumbers2]"
                      v-model="startingLoopQuestion"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                      label="Конечный вопрос цикла"
                      :items="questions"
                      :rules="[rules.required, rules.onlyLatinAndNumbers2]"
                      v-model="incrementalQuestion"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-text-field label="Начальный вопрос цикла" v-model="startingLoopQuestion"
                                :rules="[rules.required, rules.onlyLatinAndNumbers2, rules.min3]"
                  />
                </v-col>
                <v-col>
                  <v-text-field label="Конечный вопрос цикла" v-model="incrementalQuestion"
                                :rules="[rules.required, rules.onlyLatinAndNumbers2, rules.min3]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="!onlyRead">
        <v-spacer></v-spacer>
        <v-btn text color="success" :disabled="!valid" @click="handlerSaveBot">Сохранить</v-btn>
        <v-btn text color="error" @click="dialog = !dialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
    <v-card-actions v-if="onlyRead" style="max-width: 50px; background-color: #fff">
      <v-btn icon color="error" @click="dialog = !dialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import UploadSurveyLogicComponent from "@/components/BotsPageComponents/BotDialogComponent/UploadSurveyLogicComponent";

export default {
  name: "BotDialogComponent",
  computed: {
    onlyRead() {
      if (!this.botEditId) return false

      const item = this.items.find(el => el.file_name === this.botEditId)
      if (item.status_code === 'run') return true

      return item.role !== 'OWNER'
    },
    title() {
      return this.botEditId
          ? `Редактирование бота: ${this.botEditId}`
          : 'Создание бота'
    },
    dialog: {
      get() {
        return this.botDialog
      },
      set(value) {
        this.handlerChangeBotDialog(value)
      },
    },
    accessToken: {
      get() {
        return this.access_token
      },
      set(value) {
        this.handlerChangeAccessToken(value)
      }
    },
    botName: {
      get() {
        return this.botname
      },
      set(value) {
        this.handlerChangeBotName(value)
      }
    },
    botUsername: {
      get() {
        return this.bot_username
      },
      set(value) {
        this.handlerChangeBotUsername(value)
      }
    },
    uniqueName: {
      get() {
        return this.unique_name
      },
      set(value) {
        this.handlerChangeUniqueName(value)
      }
    },
    denyUsersWithoutUniqueRef: {
      get() {
        return this.deny_users_without_unique_ref
      },
      set(value) {
        this.handlerChangeDenyUsersWithoutUniqueRef(value)
      }
    },
    timerLimitsMin: {
      get() {
        return this.timer_limits[0]
      },
      set(value) {
        this.handlerChangeTimerLimits([Number(value), this.timer_limits[1]])
      }
    },
    timerLimitsMax: {
      get() {
        return this.timer_limits[1]
      },
      set(value) {
        this.handlerChangeTimerLimits([this.timer_limits[0], Number(value)])
      }
    },
    denyPollDuringTimer: {
      get() {
        return this.deny_poll_during_timer
      },
      set(value) {
        this.handlerChangeDenyPollDuringTimer(value)
      }
    },
    easyCommunicationMode: {
      get() {
        return this.easy_communication_mode
      },
      set(value) {
        this.handlerChangeEasyCommunicationMode(value)
      }
    },
    survEnd: {
      get() {
        return this.surv_end
      },
      set(value) {
        this.handlerChangeSurvEnd(value)
      }
    },
    blockRepeat: {
      get() {
        return this.BLOCK_REPEAT
      },
      set(value) {
        this.handlerChangeBlockRepeat(value)
      }
    },
    startingLoopQuestion: {
      get() {
        return this.starting_loop_question
      },
      set(value) {
        this.handlerChangeStartingLoopQuestion(value)
      }
    },
    incrementalQuestion: {
      get() {
        return this.incremental_question
      },
      set(value) {
        this.handlerChangeIncrementalQuestion(value)
      }
    },
    maxAmountOfFinishedPolls: {
      get() {
        return this.MAX_AMOUNT_OF_FINISHED_POLLS
      },
      set(value) {
        this.handlerChangeMaxAmountOfFinishedPolls(value)
      }
    },
    fileName() {
      return `config_${this.botname}_${this.unique_name}.json`
    },
    refLink() {
      return this.botUsername ? `https://t.me/${this.botUsername}?start=someRefIDhere` : 'Необходимо ввести имя бота!'
    },
    ...mapState('tgBots', [
      'botDialog', 'botEditId', 'access_token', 'botname', 'bot_username', 'unique_name',
      'deny_users_without_unique_ref', 'timer_limits', 'deny_poll_during_timer', 'surv_end', 'BLOCK_REPEAT',
      'incremental_question', 'MAX_AMOUNT_OF_FINISHED_POLLS', 'items', 'starting_loop_question', 'questions',
      'surveyLogicSrc', 'easy_communication_mode'
    ])
  },
  methods: {
    ...mapActions('tgBots', [
      'handlerChangeBotDialog', 'handlerChangeAccessToken', 'handlerChangeBotName', 'handlerChangeBotUsername',
      'handlerChangeUniqueName', 'handlerChangeDenyUsersWithoutUniqueRef',
      'handlerChangeTimerLimits', 'handlerChangeDenyPollDuringTimer', 'handlerChangeSurvEnd',
      'handlerChangeBlockRepeat', 'handlerChangeIncrementalQuestion', 'handlerChangeMaxAmountOfFinishedPolls',
      'handlerSaveBot', 'handlerChangeStartingLoopQuestion', 'handlerChangeEasyCommunicationMode'
    ]),
  },
  data() {
    return {
      valid: false,
      surveyEndingItems: ['No', 'stdRU', 'stdKZ'],
      rules: {
        required: v => !!v || 'Обязтальное поле!',
        tgToken: v => /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/i.test(v) || 'Невалидный токен.',
        onlyLatinAndNumbers1: v => /^[a-zA-Z0-9_-]*$/i.test(v) || 'Допутимы латинские буквы, цифры и _, -.',
        onlyLatinAndNumbers2: v => /^[a-zA-Z0-9_]*$/i.test(v) || 'Допутимы латинские буквы, цифры и _.',
        min3: v => v.length >= 3 || 'Минимум три символа',
        min01: v => v >= 0.1 || 'Минимальное значение не может быть менее 0.1',
        maxMoreMin: () => this.timerLimitsMin < this.timerLimitsMax
            || 'Максимальное значение не может быть меньше или равно минимальному'
      },
    }
  },
  watch: {
    valid(value) {
      if (!value && this.botEditId) this.$refs.form.validate()
    },
    dialog(value) {
      if (!value) {
        this.$refs.form.resetValidation()
      }
    },
    blockRepeat(value) {
      if (value) {
        if (!this.timer_limits) this.handlerChangeTimerLimits([1, 48])
        if (this.surveyLogicSrc === 'qma') {
          if (!this.starting_loop_question) this.handlerChangeStartingLoopQuestion(this.questions[0])
          if (!this.incremental_question) this.handlerChangeIncrementalQuestion(this.questions[this.questions.length - 1])
        }
      }
    },
  },
  components: {
    UploadSurveyLogicComponent,
  },
}
</script>
