import {reportsService} from "@/services";

export const userDialog = {
  namespaced: true,
  state: {
    message: '',
    userId: null,
    messages: [],
    loading: false,
  },
  actions: {
    handlerChangeUserId({commit}, value) {
      commit('changeUserId', value)
    },
    handlerToggleLoading({commit}) {
      commit('toggleLoading')
    },
    handlerChangeLoading({commit}, value) {
      commit('changeLoading', value)
    },
    handlerLoadConversation({commit, state}, projectName) {
      commit('changeLoading', true)
      commit('setMessages', [])
      reportsService.getConversation(projectName, state.userId)
        .then(json => {
          const messages = json.sort(
            (a, b) => (a.datetime > b.datetime) ? 1 : ((b.datetime > a.datetime) ? -1 : 0)
          )
          commit('setMessages', messages)
          commit('changeLoading', false)
        })
    },
    handlerSendMessage({commit, state, dispatch}, projectName) {
      reportsService.sendMessage(projectName, state.userId, state.message)
        .then(() => {
          dispatch('alert/success', 'Сообщение отправлено.', {root: true})
          commit('changeMessage', '')
          dispatch('handlerLoadConversation', projectName)
        })
    },
    handlerChangeMessage({commit}, value) {
      commit('changeMessage', value)
    },
    handlerCleanMessages({commit}) {
      commit('setMessages', [])
    },
  },
  mutations: {
    changeUserId(state, value) {
      state.userId = value
    },
    setMessages(state, data) {
      state.messages = data
    },
    toggleLoading(state) {
      state.loading = !state.loading
    },
    changeLoading(state, value) {
      state.loading = value
    },
    changeMessage(state, value) {
      state.message = value
    },
  },
}
