import {authService} from "@/services";
import router from "@/router";

const user = JSON.parse(localStorage.getItem('user'))


export const auth = {
  namespaced: true,
  state: {
    loggedIn: !!user,
    user: user ? user : null,
  },
  actions: {
    handlerLogin({commit}, {username, password}) {
      authService.getToken(username, password).then(auth_token => {
        if (auth_token) {
          authService.getMe().then(user => {
            commit('loginSuccess', user)
            router.push({name: 'botsPage'}).then()
          })
        }
      })
    },
    handlerLogout({commit}) {
      commit('logout')
      authService.logout()
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true
      state.user = user
    },
    logout(state) {
      state.loggedIn = false
      state.user = null
    },
  },
  getters: {},
}
