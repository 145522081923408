<template>
  <v-data-table
      :headers="headers"
      :items="itemsWithRole"
      item-key="name"
      :search="search"
      sort-by="botname"
      multi-sort
      :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000, -1] }"
  >
    <template v-slot:top>
      <v-text-field
          v-model="search"
          label="Поиск"
      ></v-text-field>
      <v-progress-linear indeterminate v-if="loading"/>
    </template>

    <template v-slot:[`item.survey_logic`]="{ item }">

      <div v-if="item.survey_logic_src === 'dimensions'">
        <v-tooltip bottom v-if="!item.is_exists_xml">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="error--text">{{ item.survey_xml }}</div>
          </template>
          <span>Файл отсутствует на сервере</span>
        </v-tooltip>

        <div v-else>{{ item.survey_xml }}</div>
      </div>

      <div v-if="item.survey_logic_src === 'qma'">
        <v-tooltip bottom v-if="!item.is_exists_qma_json">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="error--text">{{ item.survey_qma_json }}</div>
          </template>
          <span>Файл отсутствует на сервере</span>
        </v-tooltip>

        <div v-else>{{ item.survey_qma_json }}</div>
      </div>


    </template>

    <template v-slot:[`item.logs`]="{ item }">
      <v-btn v-if="item.has_log && item.role !== 'GUEST'" icon @click="handlerOpenLogDialog(item.file_name)">
        <v-icon color="primary">mdi-format-list-group</v-icon>
      </v-btn>
    </template>

    <template v-slot:[`item.controls`]="{ item }">
      <div v-if="item.role !== 'GUEST'">
        <v-btn icon v-if="item.status_code === 'ready'"
               @click="handlerSendCommand([item.file_name, 'start'])"
               :disabled="!item.is_exists_xml && !item.is_exists_qma_json || item.role !== 'OWNER'"
        >
          <v-icon color="success">mdi-play</v-icon>
        </v-btn>

        <v-btn icon v-if="item.status_code === 'run'"
               @click="handlerSendCommand([item.file_name, 'stop'])"
               :disabled="!item.is_exists_xml && !item.is_exists_qma_json || item.role !== 'OWNER'"
        >
          <v-icon color="success">mdi-stop</v-icon>
        </v-btn>

        <v-btn icon @click="handlerOpenEditBotDialog(item.file_name)">
          <v-icon color="primary">
            {{
              item.role === 'OWNER' && item.status_code !== 'run'
                  ? 'mdi-application-edit-outline'
                  : 'mdi-application-outline'
            }}
          </v-icon>
        </v-btn>

        <v-btn icon :disabled="item.role !== 'OWNER'" @click="handlerOpenRoleDialog(item.file_name)">
          <v-icon color="primary">
            mdi-account-multiple-plus
          </v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                :href="`/api/reports/${getProjectName(item.file_name)}/arch?token=${token}`"
                target="_blank"
            >
              <v-icon color="primary">
                mdi-package-down
              </v-icon>
            </v-btn>
          </template>
          <span>Скачать архив с файлами бота</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="item.role !== 'OWNER' || item.status_code === 'run'"
                v-bind="attrs"
                v-on="on"
                icon
                @click="handlerClearBot(item)"
            >
              <v-icon color="error">
                mdi-broom
              </v-icon>
            </v-btn>
          </template>
          <span>Очистка данных</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="item.role !== 'OWNER' || item.status_code === 'run'"
                v-bind="attrs"
                v-on="on"
                icon
                @click="handlerDeleteBot(item)"
            >
              <v-icon color="error">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить бота</span>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.BLOCK_REPEAT`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              v-bind="attrs"
              v-on="on"
              color="green"
              v-if="item.BLOCK_REPEAT"
          >
            mdi-check
          </v-icon>
        </template>
        <span>Tip: быстрый тест напоминалки - /set 0.0003</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.bot_username`]="{ item }">
      <a :href="`https://t.me/${item.bot_username}?start=someRefIDhere`" target="_blank">{{ item.bot_username }}</a>
    </template>

    <template v-slot:[`item.reports`]="{ item }">
      <v-btn icon @click="handlerOpenDialog(getProjectName(item.file_name))">
        <v-icon color="blue" v-if="item">mdi-chart-box-outline</v-icon>
      </v-btn>
    </template>

  </v-data-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TableComponent",
  data() {
    return {
      headers: [
        {text: 'Название бота', value: 'botname'},
        {text: 'Уникальное имя', value: 'unique_name'},
        {text: 'BotUsername', value: 'bot_username'},
        {text: 'JSON/XML логика', value: 'survey_logic'},
        {text: 'Опрос-дневник', value: 'BLOCK_REPEAT'},
        {text: 'Логи', value: 'logs', sortable: false, width: 80},
        {text: 'Управление', value: 'controls', sortable: false, width: 250},
        {text: 'Отчёты', value: 'reports', sortable: false, width: 80},
      ],
      search: '',
    }
  },
  computed: {
    token() {
      return JSON.parse(localStorage.getItem('auth_token'))
    },
    ...mapState('tgBots', ['items']),
    ...mapState('reports', ['loading']),
    ...mapGetters('tgBots', ['itemsWithRole']),
  },
  methods: {
    getProjectName(fileName) {
      const {botname, unique_name} = this.items.find(el => el.file_name === fileName)

      return `${botname}_${unique_name}`
    },
    ...mapActions('tgBots', [
      'handlerOpenEditBotDialog', 'handlerOpenLogDialog', 'handlerSendCommand', 'handlerDeleteBot', 'handlerClearBot',
    ]),
    ...mapActions('tgBotsRole', ['handlerOpenRoleDialog']),
    ...mapActions('reports', ['handlerOpenDialog', 'handlerChangeLoading']),
  },
}
</script>
