<template>
  <v-card>
    <BotDialogComponent/>
    <RolesComponent v-if="roleDialog"/>
    <LogDialogComponent v-if="logDialog"/>
    <ReportsDialogComponent v-if="reportsDialog"/>

    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn small color="success" @click="handlerOpenAddBotDialog">
        <v-icon left>mdi-plus</v-icon>
        Создать
      </v-btn>
    </v-card-title>

    <v-card-text>
      <TableComponent/>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import TableComponent from "@/components/BotsPageComponents/TableComponent";
import BotDialogComponent from "@/components/BotsPageComponents/BotDialogComponent";
import LogDialogComponent from "@/components/BotsPageComponents/LogDialogComponent";
import RolesComponent from "@/components/BotsPageComponents/RolesComponent";
import ReportsDialogComponent from "@/components/BotsPageComponents/ReportsDialogComponent";

export default {
  name: "BotsPage",
  mounted() {
    this.socketAllBots()
  },
  computed: {
    ...mapState('tgBots', ['items', 'botDialog', 'logDialog']),
    ...mapState('tgBotsRole', {roleDialog: 'dialog'}),
    ...mapState('reports', {reportsDialog: 'dialog'}),
  },
  methods: {
    socketAllBots() {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'));
      const socket = new WebSocket(`wss://${location.host}/ws/get_all_bots?token=${auth_token}`)

      socket.addEventListener('message', event => {
        this.handlerSaveItems(JSON.parse(event.data))
      })

      socket.addEventListener('close', () => {
        alert('Соединение утеряно, перезапустите страницу')
      })

      socket.addEventListener('error', () => {
        alert('Не могу установить соединение с сервером! Попробуйте перезапустить страницу!')
      })

    },
    ...mapActions('tgBots', ['handlerOpenAddBotDialog', 'handlerSaveItems'])
  },
  components: {
    TableComponent,
    BotDialogComponent,
    LogDialogComponent,
    RolesComponent,
    ReportsDialogComponent,
  },
}
</script>
