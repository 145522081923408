<template>
  <div>
    <v-app v-if="loggedIn">
      <SnackbarComponent/>
      <v-navigation-drawer
          v-model="drawer"
          app
      >
        <NavigationComponent/>
      </v-navigation-drawer>

      <v-app-bar app>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <router-view/>
        </v-container>
      </v-main>

    </v-app>

    <v-app v-else>
      <SnackbarComponent/>
      <v-main class="mt-5">
        <LoginPage/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import NavigationComponent from "@/components/NavigationComponent";
import LoginPage from "@/components/LoginPage";
import {mapState} from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent";

export default {
  name: 'App',
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    title() {
      return this.$route.meta.title
    },
    ...mapState('auth', ['loggedIn'])
  },
  components: {
    NavigationComponent,
    LoginPage,
    SnackbarComponent,
  },
}
</script>
