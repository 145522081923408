<template>
  <div>
    <div v-if="typeof message === 'string'">{{ message }}</div>

    <div v-else>
      <v-row>
        <v-col cols="2" v-if="!message.has_file">
          <v-avatar color="grey lighten-2" size="64">
            <v-icon color="grey darken-2">mdi-file</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="10">
          <strong v-if="!message.has_file">
            {{ message.file_name }}
            <div><i class="caption">Файл не сохранён в хранилище, так как это некорректный ответ на вопрос.</i></div>
          </strong>

          <div v-else>
            <MediaContentComponent
                v-if="typeof message.file_id === 'string'"
                :projectName="projectName"
                :fileId="message.file_id"
                :message="message"
            />
            <MediaContentComponent
                v-else
                v-for="fileId in message.file_id"
                :key="fileId"
                :projectName="projectName"
                :fileId="fileId"
                :message="message"
            />
            {{ message.caption }}
          </div>
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<script>
import MediaContentComponent from "@/components/BotsPageComponents/ReportsComponent/MediaContentComponent";

export default {
  name: "MessageContentComponent",
  props: {
    message: [String, Object],
    projectName: String,
  },
  components: {
    MediaContentComponent,
  },
}
</script>
