import {authHeader} from "@/helpers";
import {handleResponse, handlerFileResponse} from "@/helpers/requests";

const getAllReports = (projectName, excludeStaff) => fetch(
    `/api/reports/${projectName}/?excludeStaff=${excludeStaff}`, {
  method: 'GET',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)

const getConversation = (projectName, userId) => fetch(`/api/reports/${projectName}/dialog/${userId}`, {
  method: 'GET',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)


const deleteBot = projectName => fetch(`/api/reports/${projectName}/delete`, {
  method: 'GET',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)

const clearBot = projectName => fetch(`/api/reports/${projectName}/clear`, {
  method: 'GET',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)

const sendMessage = (projectName, userId, message) => fetch(
  `/api/reports/${projectName}/send_message/${userId}`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
    body: JSON.stringify({message})
  })
  .then(handleResponse)
  .then(json => json)

const getAllUsersId = projectName => fetch(
  `/api/reports/${projectName}/get_all_users_id`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  })
  .then(handleResponse)
  .then(json => json)

const getSilenceUsersByHours = (projectName, hours) => fetch(
  `/api/reports/${projectName}/get_silence_user_by_hours/${hours}`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  })
  .then(handleResponse)
  .then(json => json)

const sendMessages = ({projectName, message, chatIdList, isTest}) => fetch(
  `/api/reports/${projectName}/send_messages`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
    body: JSON.stringify({
      projectName,
      message,
      chatIdList,
      isTest,
    })
  })
  .then(handleResponse)
  .then(json => json)


const getMassMailingList = projectName => fetch(
  `/api/reports/${projectName}/get_mass_mailing_list`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  })
  .then(handleResponse)
  .then(json => json)

const getMassMailingStatus = ({projectName, id}) => fetch(
  `/api/reports/${projectName}/get_mass_mailing_status/${id}`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json', ...authHeader()},
  })
  .then(handleResponse)
  .then(json => json)

const getFile = link => {
  return fetch(link, {headers: authHeader()})
    .then(handlerFileResponse)
    .then(data => data)
}

export const reportsService = {
  getAllReports,
  getConversation,
  getFile,
  sendMessage,
  getAllUsersId,
  getSilenceUsersByHours,
  sendMessages,
  getMassMailingList,
  getMassMailingStatus,
  deleteBot,
  clearBot,
}
