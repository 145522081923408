<template>
  <div>
    <v-checkbox
        v-model="isDimensions"
    >
      <template v-slot:label>
        <div>
          Использовать xml-файл из <a href="https://qlib.kantar.com" @click.prevent="handleLinkClick">qlib</a>, а не
          проект из <a href="https://qma.oro.moscow" @click.prevent="handleLinkClick">QMa</a>
        </div>
      </template>
    </v-checkbox>

    <div v-if="_surveyLogicSrc === 'qma'">
      <v-row v-if="botEditId">
        <v-col>
          <v-text-field label="JSON-файл" :value="survey_qma_json" disabled/>
        </v-col>
        <v-col>
          <v-checkbox label="Выгрузить другой файл" v-model="changeFile"/>
        </v-col>
      </v-row>

      <v-autocomplete
          v-if="!botEditId || changeFile"
          :rules="[rules.projectRequired]"
          class="pt-2"
          v-model="_selectQmaProject"
          :items="qmaProjects"
          item-text="text"
          item-value="value"
          value="name"
          dense
          label="Проект в QMa"
          clearable
      />
    </div>

    <div v-if="_surveyLogicSrc === 'dimensions'">
      <v-row v-if="botEditId">
        <v-col>
          <v-text-field label="XML-файл"
                        :value="survey_xml && typeof survey_xml === 'object' ? survey_xml.name : survey_xml"
                        disabled/>
        </v-col>
        <v-col>
          <v-checkbox label="Заменить файл" v-model="changeFile"/>
        </v-col>
      </v-row>

      <v-file-input
          label="XML-файл"
          v-model="surveyXML"
          :rules="[rules.fileRequired, rules.fileAlreadyExists]"
          accept="application/xml"
          :truncate-length="90"
          v-if="!botEditId || changeFile"
      />
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {authHeader} from "@/helpers";

export default {
  name: "UploadSurveyLogicComponent",
  mounted() {
    this.getQmaProjects()
  },
  data() {
    return {
      changeFile: false,
      rules: {
        fileRequired: v => !!v || 'Выберите файл!',
        fileAlreadyExists: v => {
          return v && !this.items.find(el => el.survey_xml === v.name) || 'Файл с таким именем уже существует'
        },
        projectRequired: v => !!v || 'Выберите проект из списка!'
      },

      qmaProjects: null,
    }
  },
  computed: {
    isDimensions: {
      get() {
        return this.surveyLogicSrc === 'dimensions'
      },
      set(value) {
        this._surveyLogicSrc = value
            ? 'dimensions'
            : 'qma'
      }
    },
    _surveyLogicSrc: {
      get() {
        return this.surveyLogicSrc
      },
      set(value) {
        this.handlerChangeSurveyLogicSrc(value)
      }
    },
    _selectQmaProject: {
      get() {
        return this.selectQmaProject
      },
      set(value) {
        this.handlerChangeSelectQmaProject(value)
      }
    },
    surveyXML: {
      get() {
        return this.changeFile ? null : this.survey_xml
      },
      set(value) {
        this.handlerChangeSurveyXML(value)
      }
    },
    survey_qma_json() {
      const [{survey_qma_json}] = this.items.filter(el => el.file_name === this.botEditId)
      return survey_qma_json
    },
    ...mapState('tgBots', ['botDialog', 'botEditId', 'survey_xml', 'items', 'surveyLogicSrc', 'selectQmaProject', 'items']),
  },
  methods: {
    getQmaProjects() {
      fetch('/api/control/get_qma_projects', {method: 'GET', headers: authHeader()})
          .then(resp => resp.json())
          .then(json => {
            this.$data.qmaProjects = json.map(el => Object({
                  value: el.id,
                  text: `${el.bc ? el.bc : ''} ${el.contract ? el.contract : ''} ${el.name}`
                })
            )
          })
    },
    handleLinkClick(event) {
      event.stopPropagation();
    },
    ...mapActions('tgBots', [
      'handlerChangeSurveyXML', 'handlerChangeSurveyLogicSrc', 'handlerChangeSelectQmaProject',
    ]),
  },
  watch: {
    botDialog(value) {
      if (value) {
        this.getQmaProjects()
      }

      if (!value) {
        this.$data.changeFile = false
        this.surveyXML = null
        this._surveyLogicSrc = 'qma'
        this._selectQmaProject = null
      }
    },
  },
}
</script>
