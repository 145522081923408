import Vue from 'vue'
import VueRouter from 'vue-router'
import testPage1 from "@/components/testPage1";
import BotsPage from "@/components/BotsPage";
import LoginPage from "@/components/LoginPage";
import {authService} from "@/services";
import ViewFileComponent from "@/components/BotsPageComponents/ViewFileComponent";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'botsPage',
        component: BotsPage,
        meta: {
            title: 'Главная страница',
        },
    },
    {
        path: '/viewFile',
        name: 'viewFile',
        component: ViewFileComponent,
        props: route => ({
            fileId: route.query.fileId,
            projectName: route.query.projectName,
        }),
        meta: {
            title: 'Просмотр файла',
        },
    },
    {
        path: '/testPage1',
        name: 'testPage1',
        component: testPage1,
        meta: {
            title: 'Тестовая страница 1',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
})

router.beforeEach((to, from, next) => {
    const publicPages = ['login'];
    const authRequired = !publicPages.includes(to.name);

    if (!from.name && authRequired) {
        const auth_token = localStorage.getItem('auth_token')

        if (!auth_token) next('/login')

        authService.getMe().then()
    }

    next()
})

export default router
