<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col class="cont">
        <v-card>
          <v-card-text>
            <div class="subtitle-2">{{ fileName }}</div>

            <img
                v-if="fileType === 'image'"
                :src="blob"
                alt=""
                @contextmenu.prevent="showMenu"
                class="image"
            >
            <video
                v-else-if="fileType === 'video'"
                :src="blob"
                @contextmenu.prevent="showMenu"
                class="image"
                controls="controls"
            />
            <audio
                v-else-if="fileType === 'audio'"
                :src="blob"
                @contextmenu.prevent="showMenu"
                controls="controls"
            />

            <div v-else>
              <div class="caption">Отсутствует просмотрщик для данного типа файлов.</div>
              <a :href="blob" :download="fileName">Скачать</a>
            </div>

            <RightClickMenu
                v-if="menuVisible"
                :pos="pos"
                :blob="blob"
                :fileName="fileName"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RightClickMenu from "@/components/BotsPageComponents/RightClickMenu";
import {authHeader} from "@/helpers";

export default {
  name: "ViewFileComponent",
  data() {
    return {
      blob: null,
      fileName: null,
      fileType: null,
      fileExt: null,
      menuVisible: false,
      pos: null,
    }
  },
  props: {
    fileId: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    fetch(`api/reports/${this.projectName}/get_file`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        file_id: this.fileId,
      })
    })
        .then(resp => {
          this.$data.fileName = resp.headers.get('Content-Disposition').split('=')[1]
          // this.$data.fileType = resp.headers.get('Content-Type').split('/')[0]
          this.$data.fileType = resp.headers.get('Content-Type').split('/')[0]
          this.$data.fileExt = resp.headers.get('Content-Type').split('/')[1]

          if (this.fileType === 'image' && !['jpg', 'jpeg', 'png'].includes(this.fileExt)) {
            this.$data.fileType = null
          }

          return resp.blob()
        })
        .then(data => this.$data.blob = URL.createObjectURL(data))
  },
  methods: {
    showMenu(event) {
      event.preventDefault()
      this.menuVisible = true
      this.pos = [event.layerX, event.layerY]

      document.addEventListener('click', this.hideMenu)
    },
    hideMenu() {
      this.menuVisible = false
      document.removeEventListener('click', this.hideMenu)
    },
  },
  components: {
    RightClickMenu,
  },
}
</script>

<style scoped>

.cont {
  max-width: 800px;
  max-height: 800px;
}

.image {
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
}
</style>
