<template>
  <v-row class="mt-2">
    <LeftPanel/>
    <RightPanel/>
  </v-row>
</template>

<script>
import LeftPanel from "@/components/BotsPageComponents/ReportsComponent/MassMailingComponent/LeftPanel";
import RightPanel from "@/components/BotsPageComponents/ReportsComponent/MassMailingComponent/RightPanel";
import {mapActions} from "vuex";

export default {
  name: "MassMailingComponent",
  components: {
    LeftPanel,
    RightPanel,
  },
  methods: {
    ...mapActions('massMailing', ['handlerCleanState'])
  },
  destroyed() {
    this.handlerCleanState()
  },
}
</script>
