import {authService} from "@/services";
import vuex from "@/store";


const handlerError = (response, data) => {
  if (response.status === 401) {
    authService.logout()
    location.reload()
  }

  for (const code in data) {
    const text = typeof data[code] === 'object' ? data[code].join(' ') : data[code]
    console.log(code, text)

    vuex.dispatch('alert/error', `${code}: ${text}`).then()
  }

  const error = data || response.statusText
  return Promise.reject(error)
}

export const handlerFileResponse = response => {
  if (response.status >= 500) vuex.dispatch('alert/error', 'Что-то пошло не так...').then()

  if (!response.ok) return handleResponse(response)

  return response.blob()
    .then(data => {
      if (!response.ok) return handlerError(response, data)
      return data
    })
}

export const handleResponse = response => {
  if (response.status >= 500) vuex.dispatch('alert/error', 'Что-то пошло не так...').then()

  return response.text()
    .then(text => {
      const data = text && JSON.parse(text)

      if (!response.ok) return handlerError(response, data)
      return data
    })
}
