<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col class="cont">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="col-4">
                <v-text-field
                    label="ID пользователя"
                    v-model="_userId"
                    type="number"
                />
              </v-col>
              <v-col class="pt-4 col-2">
                <v-btn color="success" @click="handlerLoadConversation(projectName)">
                  Найти
                </v-btn>
              </v-col>

              <v-col class="pt-4 col-2" v-if="messages.length">
                <v-btn color="primary" @click="downloadFile">
                  <v-icon left>mdi-microsoft-excel</v-icon>
                  Скачать
                </v-btn>
              </v-col>
            </v-row>

            <div class="screen">
              <v-progress-linear indeterminate v-if="_loading"/>
              <div v-if="messages.length">
                <div
                    v-for="(message, i) in messages"
                    :class="['msg', 'ma-2', 'pa-2', message.itsBot ? 'bot-msg' : 'user-msg']"
                    :key="i"
                >
                  <div class="head px-2 subtitle-2">{{ message.from }}</div>
                  <div class="message px-2">
                    <MessageContentComponent
                        :message="message.message"
                        :projectName="projectName"
                    />
                  </div>
                  <div class="text-right caption">{{ message.datetime }}</div>
                </div>
              </div>
            </div>

            <v-text-field
                v-if="!!messages.length && !hideSendMessage"
                class="send-message"
                outlined
                hint="Введите сообщение..."
                v-model="_message"
            >
              <template v-slot:append>
                <v-btn small text :disabled="!_message.length" @click="handlerSendMessage(projectName)">
                  Отправить
                </v-btn>
              </template>
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {reportsService} from "@/services";
import {mapActions, mapState} from "vuex";
import MessageContentComponent from "@/components/BotsPageComponents/ReportsComponent/MessageContentComponent";

export default {
  name: "UserConversationComponent",
  mounted() {
    this.handlerCleanMessages()
  },
  props: {
    hideSendMessage: Boolean,
  },
  computed: {
    _userId: {
      get() {
        return this.userId
      },
      set(value) {
        this.handlerChangeUserId(value)
      }
    },
    _loading: {
      get() {
        return this.loading
      },
      set() {
        this.handlerToggleLoading()
      }
    },
    _message: {
      get() {
        return this.message
      },
      set(value) {
        this.handlerChangeMessage(value)
      }
    },
    ...mapState('reports', ['dialog', 'projectName', 'excludeStaff']),
    ...mapState('userDialog', ['userId', 'messages', 'loading', 'message']),
  },
  methods: {
    downloadFile() {
      this.handlerChangeLoading(true)
      this.$store.dispatch('alert/info', 'Файл подготавливается')

      reportsService.getFile(`/api/reports/${this.projectName}/dialog/${this.userId}/excel?excludeStaff=${this.excludeStaff}`)
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = e => {
              const dummy = document.createElement('a')
              dummy.href = e.target.result
              dummy.download = `${this.projectName}_${this.userId}.xlsx`
              dummy.click()

              this.$store.dispatch('alert/info', 'Файл готов к скачиванию, сейчас откроется окно сохранения')
              this.handlerChangeLoading(false)
            }
          })
    },
    ...mapActions('reports', ['handlerChangeLoading']),
    ...mapActions('userDialog', [
      'handlerChangeUserId',
      'handlerLoadConversation',
      'handlerToggleLoading',
      'handlerSendMessage',
      'handlerChangeMessage',
      'handlerCleanMessages',
    ]),
  },
  components: {
    MessageContentComponent,
  },
}
</script>

<style scoped>

.cont {
  max-width: 850px;
}

.screen {
  padding: 2px 0;
  background: #e5e5e5;
  max-width: 800px;
}

.send-message {
  max-width: 800px;
}

.msg {
  background: white;
  width: 500px;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
}

.bot-msg {
  background-color: #fff;
  margin-left: 285px !important;
}

.user-msg {
  background-color: #f6ffff;
}

</style>
