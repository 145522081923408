<template>
  <div>
    <v-snackbar
        v-for="(message, index) in messages"
        :color="message.type"
        :key="index"
        :value="true"
        :style="`bottom: ${55 * index}px`"
        :timeout="5000"
    >
      {{ message.message }}
    </v-snackbar>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SnackbarComponents",
  computed: {
    ...mapState('alert', ['messages']),
  },
}
</script>
