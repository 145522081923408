<template>
  <v-col v-if="currentMailing">
    <v-row>
      <v-divider/>
    </v-row>

    <v-row>
      <v-col>
        <h2>
          Отправлено (без учета фильтра): {{ progress }}
          <v-progress-circular
              v-if="currentMailingInProcess"
              indeterminate
              color="primary"
              :size="18"
              width="2"
          />
          <v-icon color="success" v-else>mdi-check</v-icon>
        </h2>
      </v-col>
    </v-row>

    <v-row align="center">
        <v-btn
            outlined
            small
            color="error"
            v-if="currentMailingInProcess"
            @click="$store.dispatch('massMailingById/handlerChangeInProcess', false)"
        >
          <v-icon left>mdi-stop</v-icon>
          Остановить
        </v-btn>

        <v-btn
            outlined
            small
            color="info"
            @click="$store.dispatch(
                'massMailingById/handlerSendMessages',
                'remaining'
            )"
            v-else
        >
          <v-icon left>mdi-send</v-icon>
          Отправить оставшимся
        </v-btn>

        <v-text-field
            label="Таймаут между сообщениями"
            type="Number"
            v-model="timeout"
            class="ml-2"
            style="max-width: 200px"
        />
    </v-row>

    <v-row class="col-5">
      <v-select
          label="Статус"
          :items="userStatuses"
          item-text="text"
          v-model="userStatusValue"
          clearable
          multiple
      />
    </v-row>

    <v-row>
      <v-img
          v-if="currentMailingImage"
          :src="`data:image/jpg;base64,${currentMailingImage}`"
          max-height="300"
          max-width="300"
          class="ml-8"
          style="box-shadow: 3px 3px rgba(0,0,0,0.3)"
      ></v-img>
    </v-row>

    <v-row>
      <v-data-table
          :headers="headers"
          :items="usersFiltering"
          item-key="id"
          :loading="status === 'processing' || isLoading"
          loading-text="Загрузка"
          :footer-props="{
            'items-per-page-options': [10, 50, 100, 200, 500, -1],
            'items-per-page-text':'Элементов на странице:',
          }"
          :items-per-page=50
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  :color="userStatuses.find(el => el.value === item.status).iconColor"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ userStatuses.find(el => el.value === item.status).icon }}
              </v-icon>
            </template>
            <span>{{ userStatuses.find(el => el.value === item.status).text }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom v-if="userStatuses.find(el => el.value === item.status).value === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon color="info" v-bind="attrs" v-on="on"
                  @click="$store.dispatch('massMailingById/handlerSendMessage', item.id)"
                  :disabled="currentMailingInProcess"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Отправить сообщение (впервые)</span>
          </v-tooltip>

          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon color="error" v-bind="attrs" v-on="on"
                  @click="$store.dispatch('massMailingById/handlerSendMessage', item.id)"
                  :disabled="currentMailingInProcess"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <strong>Внимание! Эта кнопка отправит сообщение еще раз!</strong>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
  </v-col>
</template>

<script>

import {mapState} from "vuex";

const WAIT = 1
const SENT = 2
const FAIL = 3

const statuses = [
  {
    value: WAIT,
    icon: 'mdi-checkbox-blank-circle-outline',
    iconColor: 'info',
    text: 'Не было отправки',
  },
  {
    value: SENT,
    icon: 'mdi-check-circle',
    iconColor: 'success',
    text: 'Отправлено',
  },
  {
    value: FAIL,
    icon: 'mdi-alert-circle-outline',
    iconColor: 'error',
    text: 'Ошибка при отправке',
  },
]

export default {
  name: "MailingStatusComponent",
  data() {
    return {
      headers: [
        {text: 'Chat ID', value: 'chatId'},
        {text: 'Статус', value: 'status'},
        {text: 'Обнолвено в', value: 'updateAt'},
        {text: 'Действие', value: 'action'},
        {text: 'Сообщение', value: 'message'},
      ],
      status: 'success',
      userStatusValue: null,
      userStatuses: statuses,
    }
  },
  computed: {
    progress() {
      const sent = this.users.filter(el => el.status !== 1).length
      return `${sent} / ${this.users.length}`
    },
    usersFiltering() {
      if (this.userStatusValue && this.userStatusValue.length) {
        return this.users.filter(el => this.userStatusValue.includes(el.status))
      }

      return this.users
    },
    timeout: {
      get() {
        return this.currentMailingTimeout
      },
      set(value) {
        this.$store.dispatch('massMailingById/handlerChangeTimeout', value)
      }
    },
    ...mapState('massMailingById', [
      'mailings', 'currentMailing', 'users', 'isLoading', 'currentMailingImage', 'currentMailingTimeout',
      'currentMailingInProcess',
    ]),
  },
}
</script>
