<template>
  <v-col class="col-12">
    <ControlMailingComponent/>
    <MailingStatusComponent/>
    <CreateMailingDialog/>
  </v-col>
</template>

<script>
import ControlMailingComponent
  from "@/components/BotsPageComponents/ReportsComponent/MassMailingByIdComponent/ControlMailingComponent";
import MailingStatusComponent
  from "@/components/BotsPageComponents/ReportsComponent/MassMailingByIdComponent/MailingStatusComponent";
import CreateMailingDialog
  from "@/components/BotsPageComponents/ReportsComponent/MassMailingByIdComponent/CreateMailingDialog";

export default {
  name: "MassMailingByIdComponent",
  mounted() {
    this.$store.dispatch('massMailingById/handlerOpenSocket')
  },
  destroyed() {
    this.$store.dispatch('massMailingById/handlerCloseSocket')
  },
  components: {
    ControlMailingComponent,
    MailingStatusComponent,
    CreateMailingDialog,
  },
}
</script>

