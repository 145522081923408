import {reportsService} from "@/services";

export const reports = {
  namespaced: true,
  state: {
    dialog: false,
    projectName: null,
    // data
    users: null,
    statFinishedPolls: null,
    usersDidntSendRefId: null,
    pollAnswers: null,
    loading: false,
    excludeStaff: false,
    role: null,
  },
  actions: {
    handlerOpenDialog({commit, dispatch}, value) {
      commit('changeProjectName', value)
      dispatch('handlerGetAllReports', value)
      commit('changeLoading', true)
    },
    handlerCloseDialog({commit}) {
      commit('toggleDialog', false)
    },
    handlerGetAllReports({commit, state}, projectName) {
      commit('toggleDialog', true)
      reportsService.getAllReports(projectName, state.excludeStaff)
        .then(json => {
          const {users, stat_finished_polls, users_didnt_send_ref_id, poll_answers, role} = json
          commit('changeUsers', users)
          commit('changeRole', role)
          commit('changeStatFinishedPolls', stat_finished_polls)
          commit('changeUsersDidntSendRefId', users_didnt_send_ref_id)
          commit(
            'changePollAnswers',
            poll_answers.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0))
          )
          commit('changeLoading', false)
          commit('toggleDialog', true)
        })
    },
    handlerChangeLoading({commit}, value) {
      commit('changeLoading', value)
    },
    handlerChangeExcludeStaff({commit}, value) {
      commit('changeExcludeStaff', value)
    },
  },
  mutations: {
    toggleDialog(state, value) {
      state.dialog = value

      if (!value) {
        state.projectName = null
        state.role = null
        state.users = null
        state.statFinishedPolls = null
        state.usersDidntSendRefId = null
        state.pollAnswers = null
      }
    },
    changeProjectName(state, value) {
      state.projectName = value
    },
    changeUsers(state, data) {
      state.users = data
    },
    changeRole(state, data) {
      state.role = data
    },
    changeStatFinishedPolls(state, data) {
      state.statFinishedPolls = data
    },
    changeUsersDidntSendRefId(state, data) {
      state.usersDidntSendRefId = data
    },
    changePollAnswers(state, data) {
      state.pollAnswers = data
    },
    changeLoading(state, value) {
      state.loading = value
    },
    changeExcludeStaff(state, value) {
      state.excludeStaff = value
    },
  },
  getters: {},
}
