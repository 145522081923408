<template>
  <div>
    <img
        v-if="fileType === 'image'"
        :src="blob"
        alt=""
        class="image"
        @contextmenu.prevent="showMenu"
    >
    <video
        v-else-if="fileType === 'video'"
        :src="blob"
        @contextmenu.prevent="showMenu"
        class="image"
        controls="controls"
    />
    <audio
        v-else-if="fileType === 'audio'"
        :src="blob"
        @contextmenu.prevent="showMenu"
        controls="controls"
    />

    <v-row v-else>
      <v-col cols="2">
        <v-avatar color="grey lighten-2" size="64">
          <v-icon color="grey darken-2">mdi-file</v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="10" class="pl-6">
        <div v-if="message.file_name">
          <strong>
            {{ message.file_name }}
          </strong>

          <div class="caption">Отсутствует просмотрщик для данного типа файлов.</div>

          <a :href="blob" :download="fileName">Скачать</a>
        </div>

        <div class="caption" v-else>
          Файл отсутствует на сервере, обратитесь к разработчикам.
        </div>

      </v-col>
    </v-row>

    <RightClickMenu
        v-if="menuVisible"
        :pos="pos"
        :blob="blob"
        :fileName="fileName"
    />
  </div>
</template>

<script>
import RightClickMenu from "@/components/BotsPageComponents/RightClickMenu";
import {authHeader} from "@/helpers";

export default {
  name: "MediaContentComponent",
  props: {
    projectName: String,
    fileId: [String, Array],
    message: Object,
  },
  mounted() {
    fetch(`api/reports/${this.projectName}/get_file`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        file_id: this.fileId,
      })
    })
        .then(resp => {
          this.$data.fileName = resp.headers.get('Content-Disposition').split('=')[1];
          [this.$data.fileType, this.$data.fileExt] = resp.headers.get('Content-Type').split('/')

          if (this.fileType === 'image' && !['jpg', 'jpeg', 'png'].includes(this.fileExt)) {
            this.$data.fileType = null
          }

          return resp.blob()
        })
        .then(data => this.$data.blob = URL.createObjectURL(data))
  },
  data() {
    return {
      blob: null,
      fileName: null,
      fileType: null,
      fileExt: null,
      menuVisible: false,
      pos: null,
    }
  },
  components: {
    RightClickMenu,
  },
  methods: {
    showMenu(event) {
      event.preventDefault()
      this.menuVisible = true
      this.pos = [event.layerX, event.layerY]

      document.addEventListener('click', this.hideMenu)
    },
    hideMenu() {
      this.menuVisible = false
      document.removeEventListener('click', this.hideMenu)
    },
  },
}
</script>

<style scoped>
.image {
  max-width: 400px;
  max-height: 400px;
  background-color: gray;
}
</style>
