import {reportsService} from "@/services";

export const massMailing = {
  namespaced: true,
  state: {
    users: [],
    massMailingList: [],
    currentMassMailing: '',
    progress: '',
    status: '',
  },
  actions: {
    handlerChangeUsersId({commit}, data) {
      commit('cleanState')
      commit('changeUsers', data.map(el => Object({id: el, status: null})))
    },
    handlerGetAllUsersId({commit, dispatch}, projectName) {
      reportsService.getAllUsersId(projectName)
        .then(ids => {
          dispatch('alert/success', 'Все ID подгружены.', {root: true})
          commit('cleanState')
          commit('changeUsers', ids.map(el => Object({id: el, status: null})))
        })
    },
    handlerGetSilenceUsersByHours({commit, dispatch}, {projectName, silenceHours}) {
      reportsService.getSilenceUsersByHours(projectName, silenceHours)
        .then(ids => {
          dispatch('alert/success', 'Все ID подгружены.', {root: true})
          commit('cleanState')
          commit('changeUsers', ids.map(el => Object({id: el, status: null})))
        })
    },
    handlerCleanState({commit}) {
      commit('cleanState')
    },
    handlerSendMessages({commit, dispatch}, {projectName, message, chatIdList, isTest}) {
      reportsService.sendMessages({projectName, message, chatIdList, isTest})
        .then(json => {
          commit('cleanState')
          dispatch('alert/success', 'Cообщение поставлены в очередь, через пару секунд будет доступен лог.', {root: true})
          setTimeout(
            () => {
              dispatch('handlerGetMassMailingList', projectName)
              setTimeout(
                () => dispatch('handlerChangeCurrentMassMailing', {projectName, id: json.sendingTime}),
                1000
              )
            }, 1000
          )
        })
    },
    handlerGetMassMailingList({commit}, projectName) {
      reportsService.getMassMailingList(projectName)
        .then(json => commit('setMassMailingList', json.id))
    },
    handlerChangeCurrentMassMailing({commit, dispatch}, data) {
      commit('changeUsers', [])
      commit('changeCurrentMassMailing', data.id)
      dispatch('handlerGetCurrentMassMailing', data)
    },
    handlerGetCurrentMassMailing({commit, dispatch, state}, data) {
      if (!state.currentMassMailing || data.id !== state.currentMassMailing) return

      reportsService.getMassMailingStatus(data)
        .then(json => {
          commit('changeUsers', json.id.users.map(el => Object({id: el.id, status: el.ok})))
          const {progress, status} = json.id
          commit('changeProgressAndStatus', {progress, status})

          if (status === 'processing') {
            setTimeout(
              () => dispatch('handlerGetCurrentMassMailing', data), 1000
            )
          }
        })
    }
  },
  mutations: {
    cleanState(state) {
      state.users = []
      state.massMailingList = []
      state.currentMassMailing = ''
      state.progress = ''
      state.status = ''
    },
    changeUsers(state, data) {
      state.users = data
    },
    setMassMailingList(state, data) {
      state.massMailingList = data
    },
    changeCurrentMassMailing(state, value) {
      state.currentMassMailing = value
    },
    changeProgressAndStatus(state, {progress, status}) {
      state.progress = progress
      state.status = status
    },
  },
  getters: {
    hasUsers(state) {
      return !!state.users.length
    },
    chatIdList(state) {
      return state.users.map(el => el.id)
    },
    selectedMassMailing(state) {
      return !!state.currentMassMailing.length
    }
  },
}
