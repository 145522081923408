<template>
  <div>
    <v-text-field
        outlined
        label="Поле для вставки id"
        v-model="value"
        :disabled="hasUsers || selectedMassMailing"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InsertIdsComponent",
  methods: {
    ...mapActions('massMailing', ['handlerChangeUsersId']),
  },
  computed: {
    value: {
      get() {
        return this.chatIdList
      },
      set(value) {
        this.handlerChangeUsersId(value.split(' '))
      },
    },
    ...mapGetters('massMailing', ['hasUsers', 'selectedMassMailing', 'chatIdList']),
  },
}
</script>
