<template>
  <v-col>
    <v-row>
        <v-select
            label="ID отправки"
            :items="massMailingList"
            v-model="_currentMassMailing"
            class="pl-3 pr-2"
        />
        <v-btn
            class="mt-3"
            outlined
            color="primary"
            :disabled="!currentMassMailing"
            @click="_currentMassMailing = ''"
        >
          Новая отправка
        </v-btn>
    </v-row>

    <p>Вставьте ниже список id для рассылки, или выберите одну из опций.</p>

    <InsertIdsComponent/>
    <OptionsComponent/>

    <v-btn
        block
        text
        class="mt-2"
        @click="cleanUsers"
        :disabled="!hasUsers || selectedMassMailing"
        color='success'
    >Очистить список
    </v-btn>

    <UsersStatusComponent/>
  </v-col>
</template>

<script>
import InsertIdsComponent
  from "@/components/BotsPageComponents/ReportsComponent/MassMailingComponent/LeftPanel/InsertIdsComponent";
import OptionsComponent
  from "@/components/BotsPageComponents/ReportsComponent/MassMailingComponent/LeftPanel/OptionsComponent";
import UsersStatusComponent
  from "@/components/BotsPageComponents/ReportsComponent/MassMailingComponent/LeftPanel/UsersStatusComponent";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "LeftPanel",
  data() {
    return {
      items: [
        {item: 'text'},
      ],
    }
  },
  mounted() {
    this.handlerGetMassMailingList(this.projectName)
  },
  components: {
    InsertIdsComponent,
    OptionsComponent,
    UsersStatusComponent,
  },
  methods: {
    cleanUsers() {
      this.handlerChangeUsersId([])
    },
    ...mapActions('massMailing', [
      'handlerChangeUsersId', 'handlerGetMassMailingList', 'handlerChangeCurrentMassMailing',
    ]),
  },
  computed: {
    _currentMassMailing: {
      get() {
        return this.currentMassMailing
      },
      set(value) {
        this.handlerChangeCurrentMassMailing({projectName: this.projectName, id: value})
      },
    },
    ...mapState('reports', ['projectName']),
    ...mapState('massMailing', ['massMailingList', 'currentMassMailing']),
    ...mapGetters('massMailing', ['hasUsers', 'selectedMassMailing']),
  },
}
</script>
