<template>
  <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      fullscreen
  >
    <v-card>

      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Log - {{ logId }}</v-toolbar-title>
      </v-toolbar>

      <v-card-title>
        <v-row class="col-12">
          <v-col>
            <v-menu
                :close-on-content-click="false"
                v-model="dateMenu"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Дата события"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="selectedDate"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="2022-01-01"
                  range
                  locale="ru-RU"
                  no-title
                  @change="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-select
                label="Уровень лога"
                multiple
                clearable
                :items="logLevelItems"
                v-model="logLevel"
            />
          </v-col>
        </v-row>

        <v-row class="col-12">
          <v-col>
            <v-data-table
                :headers="headers"
                :items="logItems"
                item-key="name"
                multi-sort
                :search="search"
                :loading="logIsLoading"
                :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000, -1] }"
            >
              <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Поиск"
                ></v-text-field>
              </template>

              <template v-slot:[`item.text`]="{ item }">
                <div v-html='item.text'/>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "LogDialogComponent",
  destroyed() {
    this.handlerCleanLog()
  },
  data() {
    return {
      search: '',
      headers: [
        {text: 'Время', value: 'date', width: 200},
        {text: 'Уровень', value: 'level', width: 100},
        {text: 'Текст', value: 'text'},
      ],

      logLevel: null,
      logLevelItems: ['INFO', 'DEBUG', 'WARNING', 'ERROR'],

      dateMenu: false,
      selectedDate: [],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.logDialog
      },
      set(value) {
        this.handlerChangeLogDialog(value)
      },
    },
    logItems() {
      let result = [...this.log]

      if (this.logLevel && this.logLevel.length) {
        result = result.filter(el => this.logLevel.includes(el.level))
      }

      if (this.selectedDate && this.selectedDate.length === 2) {
        const [minDate, maxDate] = [...this.selectedDate].sort()
        result = result.filter(el => el.date > minDate && el.date < `${maxDate} 23:59:59`)
      }

      return result
    },
    dateRangeText: {
      get() {
        let res = ''

        for (const date of this.selectedDate) {
          const [year, month, day] = date.split('-')
          if (res) res += ' - '
          res += `${day}.${month}.${year.slice(2)}`
        }
        return res
      },
      set(value) {
        if (value === null) {
          this.$data.selectedDate = []
        }
      }
    },
    ...mapState('tgBots', ['logDialog', 'logId', 'log', 'logIsLoading']),
  },
  methods: {
    ...mapActions('tgBots', ['handlerChangeLogDialog', 'handlerGetLog', 'handlerCleanLog']),
  },
}
</script>

<style>
pre {
  display: contents;
}
</style>

<style type="text/css">
/*
generated by Pygments <https://pygments.org/>
Copyright 2006-2022 by the Pygments team.
Licensed under the BSD license, see LICENSE for details.
*/
pre {
  line-height: 125%;
}

td.linenos .normal {
  color: inherit;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
}

span.linenos {
  color: inherit;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
}

td.linenos .special {
  color: #000000;
  background-color: #ffffc0;
  padding-left: 5px;
  padding-right: 5px;
}

span.linenos.special {
  color: #000000;
  background-color: #ffffc0;
  padding-left: 5px;
  padding-right: 5px;
}

body .hll {
  background-color: #ffffcc
}

body {
  background: #f8f8f8;
}

body .c {
  color: #3D7B7B;
  font-style: italic
}

/* Comment */
body .err {
  border: 1px solid #FF0000
}

/* Error */
body .k {
  color: #008000;
  font-weight: bold
}

/* Keyword */
body .o {
  color: #666666
}

/* Operator */
body .ch {
  color: #3D7B7B;
  font-style: italic
}

/* Comment.Hashbang */
body .cm {
  color: #3D7B7B;
  font-style: italic
}

/* Comment.Multiline */
body .cp {
  color: #9C6500
}

/* Comment.Preproc */
body .cpf {
  color: #3D7B7B;
  font-style: italic
}

/* Comment.PreprocFile */
body .c1 {
  color: #3D7B7B;
  font-style: italic
}

/* Comment.Single */
body .cs {
  color: #3D7B7B;
  font-style: italic
}

/* Comment.Special */
body .gd {
  color: #A00000
}

/* Generic.Deleted */
body .ge {
  font-style: italic
}

/* Generic.Emph */
body .gr {
  color: #E40000
}

/* Generic.Error */
body .gh {
  color: #000080;
  font-weight: bold
}

/* Generic.Heading */
body .gi {
  color: #008400
}

/* Generic.Inserted */
body .go {
  color: #717171
}

/* Generic.Output */
body .gp {
  color: #000080;
  font-weight: bold
}

/* Generic.Prompt */
body .gs {
  font-weight: bold
}

/* Generic.Strong */
body .gu {
  color: #800080;
  font-weight: bold
}

/* Generic.Subheading */
body .gt {
  color: #0044DD
}

/* Generic.Traceback */
body .kc {
  color: #008000;
  font-weight: bold
}

/* Keyword.Constant */
body .kd {
  color: #008000;
  font-weight: bold
}

/* Keyword.Declaration */
body .kn {
  color: #008000;
  font-weight: bold
}

/* Keyword.Namespace */
body .kp {
  color: #008000
}

/* Keyword.Pseudo */
body .kr {
  color: #008000;
  font-weight: bold
}

/* Keyword.Reserved */
body .kt {
  color: #B00040
}

/* Keyword.Type */
body .m {
  color: #666666
}

/* Literal.Number */
body .s {
  color: #BA2121
}

/* Literal.String */
body .na {
  color: #687822
}

/* Name.Attribute */
body .nb {
  color: #008000
}

/* Name.Builtin */
body .nc {
  color: #0000FF;
  font-weight: bold
}

/* Name.Class */
body .no {
  color: #880000
}

/* Name.Constant */
body .nd {
  color: #AA22FF
}

/* Name.Decorator */
body .ni {
  color: #717171;
  font-weight: bold
}

/* Name.Entity */
body .ne {
  color: #CB3F38;
  font-weight: bold
}

/* Name.Exception */
body .nf {
  color: #0000FF
}

/* Name.Function */
body .nl {
  color: #767600
}

/* Name.Label */
body .nn {
  color: #0000FF;
  font-weight: bold
}

/* Name.Namespace */
body .nt {
  color: #008000;
  font-weight: bold
}

/* Name.Tag */
body .nv {
  color: #19177C
}

/* Name.Variable */
body .ow {
  color: #AA22FF;
  font-weight: bold
}

/* Operator.Word */
body .w {
  color: #bbbbbb
}

/* Text.Whitespace */
body .mb {
  color: #666666
}

/* Literal.Number.Bin */
body .mf {
  color: #666666
}

/* Literal.Number.Float */
body .mh {
  color: #666666
}

/* Literal.Number.Hex */
body .mi {
  color: #666666
}

/* Literal.Number.Integer */
body .mo {
  color: #666666
}

/* Literal.Number.Oct */
body .sa {
  color: #BA2121
}

/* Literal.String.Affix */
body .sb {
  color: #BA2121
}

/* Literal.String.Backtick */
body .sc {
  color: #BA2121
}

/* Literal.String.Char */
body .dl {
  color: #BA2121
}

/* Literal.String.Delimiter */
body .sd {
  color: #BA2121;
  font-style: italic
}

/* Literal.String.Doc */
body .s2 {
  color: #BA2121
}

/* Literal.String.Double */
body .se {
  color: #AA5D1F;
  font-weight: bold
}

/* Literal.String.Escape */
body .sh {
  color: #BA2121
}

/* Literal.String.Heredoc */
body .si {
  color: #A45A77;
  font-weight: bold
}

/* Literal.String.Interpol */
body .sx {
  color: #008000
}

/* Literal.String.Other */
body .sr {
  color: #A45A77
}

/* Literal.String.Regex */
body .s1 {
  color: #BA2121
}

/* Literal.String.Single */
body .ss {
  color: #19177C
}

/* Literal.String.Symbol */
body .bp {
  color: #008000
}

/* Name.Builtin.Pseudo */
body .fm {
  color: #0000FF
}

/* Name.Function.Magic */
body .vc {
  color: #19177C
}

/* Name.Variable.Class */
body .vg {
  color: #19177C
}

/* Name.Variable.Global */
body .vi {
  color: #19177C
}

/* Name.Variable.Instance */
body .vm {
  color: #19177C
}

/* Name.Variable.Magic */
body .il {
  color: #666666
}

/* Literal.Number.Integer.Long */

</style>
