import {handleResponse} from "@/helpers/requests";
import {authHeader} from "@/helpers";
import router from "@/router";

const getToken = (username, password) => fetch(
  '/api/auth/token/login/',
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({username, password})
  })
  .then(handleResponse)
  .then(json => {
    if (json.auth_token) localStorage.setItem('auth_token', JSON.stringify(json.auth_token))

    return json.auth_token
  })


const getMe = () => fetch('/api/auth/users/me/', {method: 'GET', headers: authHeader()})
  .then(handleResponse)
  .then(json => {
    localStorage.setItem('user', JSON.stringify(json))
    return json
  })

const logout = () => {
  fetch('/api/auth/token/logout/', {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(JSON.parse(localStorage.getItem('auth_token')))
  }).then(() => {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('user')

    router.push({name: 'login'}).then()
  })
}

export const authService = {
  getToken,
  getMe,
  logout,
}
