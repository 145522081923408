<template>
  <v-col>
    <v-textarea
        label="Текст сообщений"
        outlined
        counter
        v-model="message"
        :disabled="selectedMassMailing"
    />
    <v-switch
        v-model="isTest"
        label="Не отправлять (тест)"
        :disabled="selectedMassMailing"
    />
    <v-btn
        color="success"
        :disabled="!hasUsers || !message.length || selectedMassMailing"
        @click="_handlerSendMessages"
    >
      Отправить
      <v-icon right>mdi-send</v-icon>
    </v-btn>
  </v-col>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "RightPanel",
  data() {
    return {
      message: '',
      isTest: true,
    }
  },
  computed: {
    ...mapState('reports', ['projectName']),
    ...mapGetters('massMailing', ['hasUsers', 'chatIdList', 'selectedMassMailing']),
  },
  methods: {
    _handlerSendMessages() {
      this.handlerSendMessages({
        projectName: this.projectName,
        message: this.message,
        chatIdList: this.chatIdList,
        isTest: this.isTest,
      })
      this.$data.message = ''
    },
    ...mapActions('massMailing', ['handlerSendMessages']),
  },
}
</script>
