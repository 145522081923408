<template>
  <v-row class="col-6">
    <v-select
        label="ID отправки"
        :items="mailings"
        item-text="mailingName"
        item-value="id"
        v-model="_currentMailing"
        class="pl-3 pr-2"
        clearable
    />
    <v-btn
        @click="handlerNewMailing"
        class="mt-3"
        outlined
        color="primary"
    >
      Новая отправка
    </v-btn>
  </v-row>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ControlMailingComponent",
  methods: {
    handlerNewMailing() {
      this.$store.dispatch('massMailingById/handlerChangeCreateMailingDialog', true)
    },
  },
  computed: {
    _currentMailing: {
      get() {
        return this.currentMailing
      },
      set(value) {
        this.$store.dispatch('massMailingById/handlerChangeCurrentMailing', value)
      }
    },
    ...mapState('massMailingById', ['mailings', 'currentMailing']),
  },
}
</script>
