import {genRandomId} from "@/helpers/common";

export const alert = {
  namespaced: true,
  state: {
    messages: [],
  },
  actions: {
    success({commit}, message) {
      commit('addMessage', {type: 'success', id: genRandomId(), message});
    },
    info({commit}, message) {
      commit('addMessage', {type: 'info', id: genRandomId(), message});
    },
    error({commit}, message) {
      commit('addMessage', {type: 'error', id: genRandomId(), message});
    },
  },
  mutations: {
    addMessage(state, message) {
      state.messages = [...state.messages, message]
      setTimeout(() => {
        state.messages = state.messages.slice(1)
      }, 5000);
    },
  }
}
