<template>
  <v-dialog
      transition="dialog-bottom-transition"
      v-model="isOpen"
      fullscreen
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Отчёты по проекту: {{ projectName }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text align="center" class="mt-10" v-if="loading">
        <v-progress-circular indeterminate size="100" width="10" color="primary"></v-progress-circular>
        <v-spacer/>
        <i>Загружаем данные... ({{ timer }} сек.)</i>
      </v-card-text>

      <v-card-text class="pt-4" v-else>
        <v-switch
            :disabled="_excludeStaff === null"
            label="Исключить сотрудников ORO"
            v-model="_excludeStaff"
            @change="handlerGetAllReports(projectName)"
        />

        <v-tabs v-model="tabValue">
          <v-tab key="0" href="#0">Users</v-tab>
          <v-tab key="1" href="#1" v-if="role !== 'GUEST'">Users no RefId</v-tab>
          <v-tab key="2" href="#2">Статистика прохождения</v-tab>
          <v-tab key="3" href="#3">Ответы по анкете</v-tab>
          <v-tab key="4" href="#4">Диалог пользователя</v-tab>
          <v-tab key="5" href="#5" v-if="role !== 'GUEST'">Рассылка</v-tab>
          <v-tab key="6" href="#6" v-if="role !== 'GUEST'">Рассылка по ID</v-tab>

          <v-tabs-items v-model="tabValue">
            <v-tab-item key="0" value="0">
              <UsersComponent/>
            </v-tab-item>
            <v-tab-item key="1" value="1">
              <UsersDidntSendRefIdComponent/>
            </v-tab-item>
            <v-tab-item key="2" value="2">
              <UsersFinishedPoolsComponent/>
            </v-tab-item>
            <v-tab-item key="3" value="3">
              <AnswersComponent/>
            </v-tab-item>
            <v-tab-item key="4" value="4">
              <UserConversationComponent :hideSendMessage="role === 'GUEST'"/>
            </v-tab-item>
            <v-tab-item key="5" value="5">
              <MassMailingComponent/>
            </v-tab-item>
            <v-tab-item key="6" value="6">
              <MassMailingByIdComponent/>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import UsersComponent from "@/components/BotsPageComponents/ReportsComponent/UsersComponent";
import UsersFinishedPoolsComponent from "@/components/BotsPageComponents/ReportsComponent/UsersFinishedPoolsComponent";
import UsersDidntSendRefIdComponent
  from "@/components/BotsPageComponents/ReportsComponent/UsersDidntSendRefIdComponent";
import AnswersComponent from "@/components/BotsPageComponents/ReportsComponent/AnswersComponent";
import UserConversationComponent from "@/components/BotsPageComponents/ReportsComponent/UserConversationComponent";
import MassMailingComponent from "@/components/BotsPageComponents/ReportsComponent/MassMailingComponent";
import MassMailingByIdComponent from "@/components/BotsPageComponents/ReportsComponent/MassMailingByIdComponent";

export default {
  name: "ReportsDialogComponent",
  data() {
    return {
      tabValue: null,
      timer: 0,
    }
  },
  mounted() {
    setInterval(() => {
      this.timer++
    }, 1000);
  },
  computed: {
    isOpen: {
      get() {
        return this.dialog
      },
      set(value) {
        if (!value) this.handlerCloseDialog()
      },
    },
    _excludeStaff: {
      get() {
        if (this.tabValue >= 4) return null
        return this.excludeStaff
      },
      set(value) {
        this.handlerChangeExcludeStaff(value)
      }
    },
    needClean() {
      const {messages, loading, userId} = this.$store.state.userDialog
      return messages.length || loading || userId
    },
    ...mapState('reports', ['dialog', 'projectName', 'excludeStaff', 'role', 'loading']),
  },
  methods: {
    ...mapActions('reports', [
      'handlerCloseDialog', 'handlerGetAllReports', 'handlerChangeExcludeStaff', 'handlerGetAllReports',
    ]),
  },
  components: {
    UsersComponent,
    UsersFinishedPoolsComponent,
    UsersDidntSendRefIdComponent,
    AnswersComponent,
    UserConversationComponent,
    MassMailingComponent,
    MassMailingByIdComponent,
  },
  watch: {
    tabValue(value) {
      if (value !== '4' && this.needClean) {
        this.$store.dispatch('userDialog/handlerCleanMessages')
        this.$store.dispatch('userDialog/handlerChangeUserId', null)
        this.$store.dispatch('userDialog/handlerChangeLoading', false)
      }
    },
    loading() {
      this.timer = 0
    },
  },
}
</script>

