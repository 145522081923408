<template>
  <v-expansion-panels :disabled="hasUsers || selectedMassMailing">
    <v-expansion-panel>
      <v-expansion-panel-header>Выбор опции</v-expansion-panel-header>
      <v-expansion-panel-content>

        <v-text-field
            :disabled="hasUsers || selectedMassMailing"
            outlined
            label="Те, кто молчит более X часов"
            v-model="silenceHours"
        >
          <template v-slot:append>
            <v-btn
                text
                small
                :disabled="!silenceHours.length || hasUsers || selectedMassMailing"
                @click="handlerGetSilenceUsersByHours({projectName, silenceHours})"
            >
              Отобрать
            </v-btn>
          </template>
        </v-text-field>

        <div>
          <v-btn
              :disabled="hasUsers || selectedMassMailing"
              block text outlined
              @click="handlerGetAllUsersId(projectName)"
          >
            Выбрать всех
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel></v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "OptionsComponent",
  data() {
    return {
      silenceHours: '',
    }
  },
  computed: {
    ...mapGetters('massMailing', ['hasUsers', 'selectedMassMailing']),
    ...mapState('reports', ['projectName']),
  },
  methods: {
    ...mapActions('massMailing', ['handlerGetAllUsersId', 'handlerGetSilenceUsersByHours'])
  },
}
</script>
