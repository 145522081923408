import {reportsService, tgBotsService} from "@/services";

export const tgBots = {
  namespaced: true,
  state: {
    items: [],
    log: [],
    logIsLoading: false,

    botDialog: false,
    botEditId: null,

    access_token: '',
    botname: '',
    bot_username: '',
    unique_name: '',
    survey_xml: null,
    survey_qma_json: null,
    deny_users_without_unique_ref: false,
    surv_end: 'stdRU',
    BLOCK_REPEAT: false,
    timer_limits: [1, 48],
    deny_poll_during_timer: true,
    easy_communication_mode: true,
    starting_loop_question: '',
    incremental_question: '',
    questions: [],
    MAX_AMOUNT_OF_FINISHED_POLLS: 10,

    logDialog: false,
    logId: null,

    surveyLogicSrc: 'qma',
    selectQmaProject: null,
  },
  actions: {
    handlerSaveItems({commit}, data) {
      commit('saveItems', data)
    },
    handlerCleanLog({commit}) {
      commit('saveLog', [])
    },
    handlerChangeBotDialog({commit}, value) {
      commit('changeBotDialog', value)
    },
    handlerOpenAddBotDialog({commit}) {
      commit('changeBotDialog', true)
    },
    handlerOpenEditBotDialog({commit, state}, fileName) {
      commit('changeBotEditId', fileName)

      const {
        access_token, botname, bot_username, unique_name, survey_xml, deny_users_without_unique_ref, timer_limits,
        deny_poll_during_timer, surv_end, BLOCK_REPEAT, incremental_question, MAX_AMOUNT_OF_FINISHED_POLLS,
        starting_loop_question, questions, easy_communication_mode,
      } = state.items.find(el => el.file_name === fileName)

      commit('changeAccessToken', access_token)
      commit('changeBotName', botname)
      commit('changeBotUsername', bot_username)
      commit('changeUniqueName', unique_name)
      commit('changeSurveyXML', survey_xml)
      if (survey_xml) commit('changeSurveyLogicSrc', 'dimensions')
      commit('changeDenyUsersWithoutUniqueRef', deny_users_without_unique_ref)
      commit('changeBlockRepeat', BLOCK_REPEAT)
      commit('changeSurvEnd', surv_end)
      commit('changeQuestions', questions)
      commit('changeEasyCommunicationMode', easy_communication_mode)
      if (BLOCK_REPEAT) {
        commit('changeTimerLimits', timer_limits)
        commit('changeDenyPollDuringTimer', deny_poll_during_timer)

        if (questions) {
          if (questions.includes(starting_loop_question)) commit('changeStartingLoopQuestion', starting_loop_question)
          if (questions.includes(incremental_question)) commit('changeIncrementalQuestion', incremental_question)
        } else {
          commit('changeStartingLoopQuestion', starting_loop_question)
          commit('changeIncrementalQuestion', incremental_question)
        }
        commit('changeMaxAmountOfFinishedPolls', MAX_AMOUNT_OF_FINISHED_POLLS)
      }

      commit('changeBotDialog', true)
    },
    handlerOpenLogDialog({commit, state}, fileName) {
      commit('changeLogId', fileName)
      commit('changeLogDialog', true)

      commit('changeLogIsLoading', true)

      const {botname, unique_name} = state.items.find(el => el.file_name === fileName)

      tgBotsService.getLog(`${botname}_${unique_name}`).then(data => {
        commit('saveLog', data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0)))
        commit('changeLogIsLoading', false)
      })

    },
    handlerChangeLogDialog({commit}, value) {
      commit('changeLogDialog', value)
    },
    handlerChangeAccessToken({commit}, value) {
      commit('changeAccessToken', value)
    },
    handlerChangeBotName({commit}, value) {
      commit('changeBotName', value)
    },
    handlerChangeBotUsername({commit}, value) {
      commit('changeBotUsername', value)
    },
    handlerChangeUniqueName({commit}, value) {
      commit('changeUniqueName', value)
    },
    handlerChangeSurveyXML({commit}, value) {
      commit('changeSurveyXML', value)
    },
    handlerChangeDenyUsersWithoutUniqueRef({commit}, value) {
      commit('changeDenyUsersWithoutUniqueRef', value)
    },
    handlerChangeTimerLimits({commit}, value) {
      commit('changeTimerLimits', value)
    },
    handlerChangeDenyPollDuringTimer({commit}, value) {
      commit('changeDenyPollDuringTimer', value)
    },
    handlerChangeSurvEnd({commit}, value) {
      commit('changeSurvEnd', value)
    },
    handlerChangeBlockRepeat({commit}, value) {
      commit('changeBlockRepeat', value)
    },
    handlerChangeStartingLoopQuestion({commit}, value) {
      commit('changeStartingLoopQuestion', value)
    },
    handlerChangeIncrementalQuestion({commit}, value) {
      commit('changeIncrementalQuestion', value)
    },
    handlerChangeMaxAmountOfFinishedPolls({commit}, value) {
      commit('changeMaxAmountOfFinishedPolls', value)
    },
    handlerChangeEasyCommunicationMode({commit}, value) {
      commit('changeEasyCommunicationMode', value)
    },
    handlerSaveBot({state, commit, dispatch}) {
      tgBotsService.saveBot({
        file_name: state.botEditId,
        access_token: state.access_token,
        botname: state.botname,
        bot_username: state.bot_username,
        unique_name: state.unique_name,
        survey_logic_src: state.surveyLogicSrc,
        survey_xml: state.survey_xml,
        select_qma_project: state.selectQmaProject,
        deny_users_without_unique_ref: state.deny_users_without_unique_ref,
        surv_end: state.surv_end,
        BLOCK_REPEAT: state.BLOCK_REPEAT,
        timer_limits: state.timer_limits,
        deny_poll_during_timer: state.deny_poll_during_timer,
        easy_communication_mode: state.easy_communication_mode,
        starting_loop_question: state.starting_loop_question,
        incremental_question: state.incremental_question,
        MAX_AMOUNT_OF_FINISHED_POLLS: state.MAX_AMOUNT_OF_FINISHED_POLLS,
      })
        .then(json => {
          dispatch('alert/success', json.message, {root: true})

          commit('changeBotDialog')
        })
    },
    handlerSendCommand({dispatch, state}, [projectName, command]) {
      if (command === 'stop') {
        const botUsername = state.items.find(el => el.file_name === projectName).bot_username

        if (confirm(`Вы действительно хотите остановить бота ${botUsername}!?`)) {
          const confirmation = prompt(`Для остановки бота введите его имя:\n${botUsername}`)

          if (confirmation !== botUsername) {
            dispatch(
              'alert/error',
              `Бот "${botUsername}" НЕ остановлен, так как вы ввели неверное имя!`,
              {root: true}
            )
            return null
          }
        } else {
          return null
        }
      }

      tgBotsService.sendCommand(projectName, command)
        .then(json => {
          dispatch('alert/success', json.message, {root: true})
        })
    },
    handlerChangeSurveyLogicSrc({commit}, value) {
      commit('changeSurveyLogicSrc', value)
    },
    handlerChangeSelectQmaProject({commit, state}, value) {
      commit('changeSelectQmaProject', value)

      if (value) {
        tgBotsService.getQmaQuestions(state.selectQmaProject)
          .then(json => {
            commit('changeQuestions', json)
            // если выбран какой-то другой проект, меняем значения
            const projectItem = state.items.find(el => el.file_name === state.botEditId)
            if (!projectItem || projectItem.select_qma_project !== value) {
              commit('changeStartingLoopQuestion', json[0])
              commit('changeIncrementalQuestion', json[json.length - 1])
            } else {
              const items = state.items.find(el => el.file_name === state.botEditId)
              commit('changeStartingLoopQuestion', items.starting_loop_question)
              commit('changeIncrementalQuestion', items.incremental_question)
            }
          })
          .catch(() => {
            commit('changeSelectQmaProject', null)
          })
      }
    },
    handlerDeleteBot({dispatch}, {file_name, botname, unique_name}) {
      if (confirm(`Вы действительно хотите УДАЛИТЬ бота ${file_name}!?`)) {
        const confirmation = prompt(`Для УДАЛЕНИЯ бота введите его имя:\n${file_name}`)

        if (confirmation !== file_name) {
          dispatch(
            'alert/error',
            `Бот "${file_name}" НЕ УДАЛЕН, так как вы ввели неверное имя!`,
            {root: true}
          )
          return null
        }
      } else {
        return null
      }

      dispatch('reports/handlerChangeLoading', true, {root: true})
      dispatch('alert/info', 'Подготавливаем проект к удалению', {root: true})

      const projectName = `${botname}_${unique_name}`
      reportsService.deleteBot(projectName)
        .then(() => {
          dispatch('alert/info', 'Проект удален', {root: true})
          dispatch('reports/handlerChangeLoading', false, {root: true})
        })
    },
    handlerClearBot({dispatch}, {file_name, botname, unique_name}) {
      if (confirm(`Вы действительно хотите ОЧИСТИТЬ данные для бота ${file_name}!?`)) {
        const confirmation = prompt(`Для ОЧИСТКИ данных бота введите его имя:\n${file_name}`)

        if (confirmation !== file_name) {
          dispatch(
            'alert/error',
            `Бот "${file_name}" НЕ ОЧИЩЕН, так как вы ввели неверное имя!`,
            {root: true}
          )
          return null
        }
      } else {
        return null
      }

      dispatch('reports/handlerChangeLoading', true, {root: true})
      dispatch('alert/info', 'Подготавливаем проект к очистки', {root: true})

      const projectName = `${botname}_${unique_name}`
      reportsService.clearBot(projectName)
        .then(() => {
          dispatch('alert/info', 'Данные очищены', {root: true})
          dispatch('reports/handlerChangeLoading', false, {root: true})
        })
    },
  },
  mutations: {
    saveItems(state, data) {
      state.items = data
    },
    saveLog(state, data) {
      state.log = data
    },
    changeBotDialog(state, value) {
      state.botDialog = value

      if (!value) {
        state.botEditId = null

        state.access_token = ''
        state.botname = ''
        state.bot_username = ''
        state.unique_name = ''
        state.survey_xml = null
        state.deny_users_without_unique_ref = false
        state.surv_end = 'stdRU'
        state.BLOCK_REPEAT = false
        state.timer_limits = [1, 48]
        state.deny_poll_during_timer = true
        state.easy_communication_mode = true
        state.starting_loop_question = ''
        state.incremental_question = ''
        state.MAX_AMOUNT_OF_FINISHED_POLLS = 10
        state.questions = []
      }
    },
    changeLogDialog(state, value) {
      state.logDialog = value
    },
    changeBotEditId(state, value) {
      state.botEditId = value
    },

    changeAccessToken(state, value) {
      state.access_token = value
    },
    changeBotName(state, value) {
      state.botname = value
    },
    changeBotUsername(state, value) {
      state.bot_username = value
    },
    changeUniqueName(state, value) {
      state.unique_name = value
    },
    changeSurveyXML(state, value) {
      state.survey_xml = value
    },
    changeDenyUsersWithoutUniqueRef(state, value) {
      state.deny_users_without_unique_ref = value
    },
    changeTimerLimits(state, value) {
      state.timer_limits = value
    },
    changeDenyPollDuringTimer(state, value) {
      state.deny_poll_during_timer = value
    },
    changeSurvEnd(state, value) {
      state.surv_end = value
    },
    changeBlockRepeat(state, value) {
      state.BLOCK_REPEAT = value
    },
    changeQuestions(state, value) {
      state.questions = value
    },
    changeStartingLoopQuestion(state, value) {
      state.starting_loop_question = value
    },
    changeIncrementalQuestion(state, value) {
      state.incremental_question = value
    },
    changeMaxAmountOfFinishedPolls(state, value) {
      state.MAX_AMOUNT_OF_FINISHED_POLLS = value
    },
    changeEasyCommunicationMode(state, value) {
      state.easy_communication_mode = value
    },
    changeLogId(state, value) {
      state.logId = value
    },
    changeLogIsLoading(state, value) {
      state.logIsLoading = value
    },
    changeSurveyLogicSrc(state, value) {
      state.surveyLogicSrc = value
    },
    changeSelectQmaProject(state, value) {
      state.selectQmaProject = value
    },
  },
  getters: {
    itemsWithRole: state => state.items.filter(el => el.role !== null)
  },
}
