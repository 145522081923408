<template>
  <v-card
      class="mx-auto"
      max-width="344"
  >
    <v-form @submit.prevent="handleSubmit">
      <v-card-title class="primary--text">Вход в систему</v-card-title>

      <v-card-text>
        <v-text-field
            label="Логин"
            hint="Логин корпоративной учётки (Ivan.Ivanov)"
            persistent-hint
            v-model="username"
            autocomplete="username"
            name="username"
        />
        <v-text-field
            label="Пароль"
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            name="current-password"
            autocomplete="current-password"
            id="current-password"
            counter
        />
      </v-card-text>

      <v-card-actions>
        <v-btn text type="submit" :disabled="!username || !password">
          Войти
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "LoginPage",
  created() {
    this.handlerLogout
  },
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      showPassword: false,
    }
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const {username, password} = this;

      if (username && password) {
        this.handlerLogin({username, password})
      }
    },
    ...mapActions('auth', ['handlerLogin', 'handlerLogout'])
  },
}
</script>
