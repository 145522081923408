import {authHeader} from "@/helpers";
import {handleResponse} from "@/helpers/requests";


const getLog = projectName => fetch(`/api/control/get_log/${projectName}`, {method: 'GET', headers: authHeader()})
  .then(handleResponse)
  .then(json => json)

const saveBot = kwargs => {
  const formData = new FormData()

  if (typeof kwargs.survey_xml === 'object') {
    formData.append('survey_xml', kwargs.survey_xml)
  }

  formData.append('json', JSON.stringify(kwargs))

  const requestOptions = {
    method: kwargs.file_name ? 'PUT' : 'POST', headers: {...authHeader()}, body: formData,
  }

  return fetch('/api/control/save_bot/', requestOptions)
    .then(handleResponse)
    .then(json => json)
}

const sendCommand = (projectName, command) => fetch(`/api/control/${projectName}/${command}/`, {
  method: 'GET',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)


const getRoles = projectName => fetch(`/api/control/roles/${projectName}/`, {
  method: 'GET',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)


const getUsers = () => fetch(`/api/control/users/`, {method: 'GET', headers: authHeader()})
  .then(handleResponse)
  .then(json => json)

const addUser = (projectName, user) => fetch(`/api/control/roles/${projectName}/${user}/`, {
  method: 'POST',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)

const changeRole = (projectName, user, role) => fetch(`/api/control/roles/${projectName}/${user}/${role}/`, {
  method: 'PUT',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)

const removeUser = (projectName, user) => fetch(`/api/control/roles/${projectName}/${user}/`, {
  method: 'DELETE',
  headers: authHeader()
})
  .then(handleResponse)
  .then(json => json)

const getQmaQuestions = projectId => fetch(`/api/control/get_qma_questions/${projectId}/`,
  {method: 'GET', headers: authHeader()})
  .then(handleResponse)
  .then(json => json)


export const tgBotsService = {
  getLog,
  saveBot,
  sendCommand,
  getRoles,
  getUsers,
  addUser,
  changeRole,
  removeUser,
  getQmaQuestions,
}
