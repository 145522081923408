<template>
  <div>
    <v-data-table
        :search="search"
        :headers="headers"
        :loading="!headers.length"
        :items="items"
        item-key="name"
        multi-sort
        :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000, -1] }"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Поиск"
        ></v-text-field>
      </template>
    </v-data-table>

    <v-btn color="success" @click="downloadFile">
      <v-icon left>mdi-microsoft-excel</v-icon>
      Скачать
    </v-btn>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {reportsService} from "@/services";

export default {
  name: "UsersComponent",
  data() {
    return {
      search: '',
    }
  },
  computed: {
    headers() {
      if (!this.users.length) return []
      return Object.keys(this.users[0]).map(el => Object({text: el, value: el}))
    },
    items() {
      if (!this.headers || !this.users) return []
      return this.users
    },
    ...mapState('reports', ['users', 'projectName', 'excludeStaff']),
  },
  methods: {
    downloadFile() {
      this.handlerChangeLoading(true)
      this.$store.dispatch('alert/info', 'Файл подготавливается')

      reportsService.getFile(`/api/reports/${this.projectName}/excel/users?excludeStaff=${this.excludeStaff}`)
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = e => {
              const dummy = document.createElement('a')
              dummy.href = e.target.result
              dummy.download = `users.xlsx`
              dummy.click()

              this.$store.dispatch('alert/info', 'Файл готов к скачиванию, сейчас откроется окно сохранения')
              this.handlerChangeLoading(false)
            }
          })
    },
    ...mapActions('reports', ['handlerChangeLoading']),
  }
}
</script>
