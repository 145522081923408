<template>
  <v-data-table
      v-if="!!users.length"
      :headers="headers"
      :items="users"
      :items-per-page="-1"
      class="elevation-1 mt-2"
      :loading="status === 'processing'"
  >
    <template v-slot:top>
      <h2>
        Отправлено: {{ progress }}
        <v-icon :color="statusIcon.color">{{ statusIcon.icon }}</v-icon>
      </h2>
    </template>
  </v-data-table>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "UsersStatusComponent",
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Статус', value: 'status'},
      ],
    }
  },
  computed: {
    statusIcon: {
      get() {
        if (this.status === 'success') {
          return {
            icon: 'mdi-check',
            color: 'success',
          }
        }

        if (this.status === 'error') {
          return {
            icon: 'mdi-alert',
            color: 'error',
          }
        }

        return {
          icon: '',
          color: '',
        }
      },
    },
    ...mapState('massMailing', ['users', 'progress', 'status']),
  },
}
</script>
