<template>
  <v-dialog
      scrollable
      transition="dialog-top-transition"
      v-model="_dialog"
      class="mx-auto"
      max-width="600"
  >
    <v-card>
      <v-card-title>Доступ к проекту: {{ projectName }}</v-card-title>

      <v-card-text>
        <v-autocomplete :items="_users" @change="handlerAddUser"/>

        <template v-for="(user, index) in projectUsers">

          <v-row :key="user.username">
            <v-col class="mt-6 label-username">
              <v-list-item-title v-text="user.username"></v-list-item-title>
            </v-col>
            <v-col class="col-7">
              <v-select
                  :disabled="user.username === authUser.username"
                  :items="user.is_guest ? ['GUEST'] : ['OWNER', 'VIEWER', 'GUEST']"
                  :value="user.text_code_role"
                  @change="role => handlerChangeRole({user, role})"
              />
            </v-col>
            <v-col class="mt-4 col-1">
              <v-btn icon class="error--text" @click="handlerRemoveUser(user)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider
              v-if="index < projectUsers.length - 1"
              :key="index"
          ></v-divider>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn class="error--text" text @click="_dialog = false">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "RolesComponent",
  computed: {
    _dialog: {
      get() {
        return this.dialog
      },
      set(value) {
        if (!value) this.handlerCloseRoleDialog()
      },
    },
    _users() {
      if (!this.projectUsers) return []
      return this.users.filter(el => !this.projectUsers.map(el => el.username).includes(el))
    },
    ...mapState('tgBotsRole', ['dialog', 'projectName', 'projectUsers', 'users']),
    ...mapState('auth', {authUser: "user"})
  },
  methods: {
    ...mapActions('tgBotsRole', ['handlerCloseRoleDialog', 'handlerAddUser', 'handlerRemoveUser', 'handlerChangeRole']),
  },
}
</script>

<style>
.label-username {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
