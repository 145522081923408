import {tgBotsService} from "@/services";

export const tgBotsRole = {
  namespaced: true,
  state: {
    dialog: false,
    projectName: null,
    projectUsers: null,
    users: null,
  },
  actions: {
    handlerOpenRoleDialog({commit}, projectName) {
      tgBotsService.getUsers().then(users => commit('saveUsers', users))
      tgBotsService.getRoles(projectName).then(users => commit('openRoleDialog', {projectName, users}))
    },
    handlerCloseRoleDialog({commit}) {
      commit('closeRoleDialog')
    },
    handlerAddUser({commit, state, dispatch}, user) {
      tgBotsService.addUser(state.projectName, user).then(data => {
        commit('addUser', data)
        dispatch('alert/success', 'Пользователь добавлен в проект.', {root: true})
      })
    },
    handlerRemoveUser({commit, state, dispatch}, user) {
      tgBotsService.removeUser(state.projectName, user.username).then(() => {
        commit('removeUser', user)
        dispatch('alert/success', 'Пользователь удалён из проекта.', {root: true})
      })
    },
    handlerChangeRole({commit, state, dispatch}, {user, role}) {
      tgBotsService.changeRole(state.projectName, user.username, role).then(data => {
        commit('changeRole', data)
        dispatch('alert/success', 'Права пользователя на проекте изменены.', {root: true})
      })
    },
  },
  mutations: {
    openRoleDialog(state, {projectName, users}) {
      state.dialog = true
      state.projectName = projectName
      state.projectUsers = users
    },
    closeRoleDialog(state) {
      state.dialog = false
      state.projectName = null
      state.projectUsers = null
    },
    saveUsers(state, users) {
      state.users = users
    },
    addUser(state, data) {
      state.projectUsers = [...state.projectUsers, data]
    },
    removeUser(state, user) {
      const idx = state.projectUsers.indexOf(user)
      state.projectUsers = [...state.projectUsers.slice(0, idx), ...state.projectUsers.slice(idx + 1)]
    },
    changeRole(state, data) {
      const idx = state.projectUsers.indexOf(state.projectUsers.find(el => el.username === data.username))
      state.projectUsers = [...state.projectUsers.slice(0, idx), data, ...state.projectUsers.slice(idx + 1)]
    },
  },
}
