import Vue from 'vue'
import Vuex from 'vuex'
import {tgBots} from "@/store/tgBots.module";
import {auth} from "@/store/auth.module";
import {alert} from "@/store/alert.module";
import {tgBotsRole} from "@/store/tgBotsRole.module";
import {reports} from "@/store/reports.module";
import {userDialog} from "@/store/userDialog.module";
import {massMailing} from "@/store/massMailing.module";
import {massMailingById} from "@/store/massMailingById.module";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    massMailing,
    massMailingById,
    reports,
    tgBotsRole,
    tgBots,
    auth,
    alert,
    userDialog,
  },
})
