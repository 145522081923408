<template>
  <v-list class="menu" :style="{ left: pos[0] + 'px', top: pos[1] + 'px' }">
    <v-list-item-group color="primary">
      <v-list-item :href="blob" :download="fileName">
        <v-list-item-icon>
          <v-icon>mdi-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Скачать</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "RightClickMenu",
  props: {
    blob: String,
    fileName: String,
    pos: Array,
  },
}
</script>

<style>
.menu {
  position: absolute;
}
</style>
