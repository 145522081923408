<template>
  <v-dialog
      scrollable
      transition="dialog-top-transition"
      v-model="isOpen"
      max-width="800"
  >
    <v-card>
      <v-card-title>Создать рассылку</v-card-title>
      <v-card-text>
        <v-text-field
            label="Название"
            v-model="mailingName"
        />
        <v-file-input
            v-model="selectedExcel"
            label="Excel файл"
            prepend-icon="mdi-microsoft-excel"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <v-checkbox
            v-model="addImage"
            label="Добавить изображение к каждому сообщению"
        ></v-checkbox>
        <v-file-input
            v-if="addImage"
            v-model="selectedImage"
            @change="onImageChange"
            label="Изображение"
            prepend-icon="mdi-image"
            accept="image/*"
        />
        <v-img
            v-if="imageDataUrl"
            :src="imageDataUrl"
            ref="cmdImage"
            max-height="300"
            max-width="300"
            class="ml-8"
            style="box-shadow: 3px 3px rgba(0,0,0,0.3)"
        ></v-img>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            color="primary"
            :disabled="isValid"
            @click="$store.dispatch('massMailingById/handlerCreateMailing', $data)"
        >
          Создать
        </v-btn>
        <v-btn class="error--text" text @click="isOpen = false">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

const initState = {
  selectedExcel: null,
  addImage: false,
  selectedImage: null,
  imageDataUrl: null,
  mailingName: null,
}

export default {
  name: "CreateMailingDialog",
  data() {
    return {
      ...initState,
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.massMailingById.createMailingDialog
      },
      set(value) {
        this.$store.dispatch('massMailingById/handlerChangeCreateMailingDialog', value)
      },
    },
    isValid() {
      if (!this.mailingName) return true

      if (this.addImage) {
        return !this.selectedExcel || !this.selectedImage
      }

      return !this.selectedExcel
    },
    ...mapState('reports', ['projectName']),
  },
  methods: {
    onImageChange() {
      if (!this.selectedImage) {
        this.imageDataUrl = null
        return
      }

      const reader = new FileReader()
      reader.onload = this.onImageRead
      reader.readAsDataURL(this.selectedImage)
    },
    onImageRead(event) {
      this.imageDataUrl = event.target.result
    },
  },
  watch: {
    addImage(value) {
      if (!value) {
        this.selectedImage = null
        this.imageDataUrl = null
      }
    },
    isOpen(value) {
      if (!value) {
        Object.assign(this.$data, initState)
      }
    },
  }
}
</script>
