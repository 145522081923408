var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentMailing)?_c('v-col',[_c('v-row',[_c('v-divider')],1),_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" Отправлено (без учета фильтра): "+_vm._s(_vm.progress)+" "),(_vm.currentMailingInProcess)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":18,"width":"2"}}):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)])],1),_c('v-row',{attrs:{"align":"center"}},[(_vm.currentMailingInProcess)?_c('v-btn',{attrs:{"outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.$store.dispatch('massMailingById/handlerChangeInProcess', false)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-stop")]),_vm._v(" Остановить ")],1):_c('v-btn',{attrs:{"outlined":"","small":"","color":"info"},on:{"click":function($event){return _vm.$store.dispatch(
              'massMailingById/handlerSendMessages',
              'remaining'
          )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_vm._v(" Отправить оставшимся ")],1),_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"200px"},attrs:{"label":"Таймаут между сообщениями","type":"Number"},model:{value:(_vm.timeout),callback:function ($$v) {_vm.timeout=$$v},expression:"timeout"}})],1),_c('v-row',{staticClass:"col-5"},[_c('v-select',{attrs:{"label":"Статус","items":_vm.userStatuses,"item-text":"text","clearable":"","multiple":""},model:{value:(_vm.userStatusValue),callback:function ($$v) {_vm.userStatusValue=$$v},expression:"userStatusValue"}})],1),_c('v-row',[(_vm.currentMailingImage)?_c('v-img',{staticClass:"ml-8",staticStyle:{"box-shadow":"3px 3px rgba(0,0,0,0.3)"},attrs:{"src":`data:image/jpg;base64,${_vm.currentMailingImage}`,"max-height":"300","max-width":"300"}}):_vm._e()],1),_c('v-row',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usersFiltering,"item-key":"id","loading":_vm.status === 'processing' || _vm.isLoading,"loading-text":"Загрузка","footer-props":{
          'items-per-page-options': [10, 50, 100, 200, 500, -1],
          'items-per-page-text':'Элементов на странице:',
        },"items-per-page":50},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.userStatuses.find(el => el.value === item.status).iconColor}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.userStatuses.find(el => el.value === item.status).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.userStatuses.find(el => el.value === item.status).text))])])]}},{key:`item.action`,fn:function({ item }){return [(_vm.userStatuses.find(el => el.value === item.status).value === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"info","disabled":_vm.currentMailingInProcess},on:{"click":function($event){return _vm.$store.dispatch('massMailingById/handlerSendMessage', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("Отправить сообщение (впервые)")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","disabled":_vm.currentMailingInProcess},on:{"click":function($event){return _vm.$store.dispatch('massMailingById/handlerSendMessage', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('strong',[_vm._v("Внимание! Эта кнопка отправит сообщение еще раз!")])])]}}],null,true)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }