import vuex from "@/store/index";

const initState = {
    mailings: [],
    currentMailing: null,
    currentMailingImage: null,
    createMailingDialog: false,
    currentMailingInProcess: null,
    currentMailingTimeout: null,
    socket: null,
    users: [],
    isLoading: true,
}

export const massMailingById = {
    namespaced: true,
    state: {
        ...initState,
    },
    actions: {
        handlerOpenSocket({commit, rootState}) {
            const auth_token = JSON.parse(localStorage.getItem('auth_token'));
            const ws = `wss://${location.host}/ws/mass_mailing_by_id?token=${auth_token}&project_name=${rootState.reports.projectName}`
            const socket = new WebSocket(ws)

            socket.onmessage = ({data}) => {
                const query = JSON.parse(data)
                commit(query.action, query.payload)
            }

            socket.onclose = () => {
                console.info('Close socket mass_mailing_by_id')
            }

            commit('openSocket', socket)
        },
        handlerCloseSocket({commit}) {
            commit('closeSocket')
        },
        handlerChangeCurrentMailing({commit}, value) {
            commit('changeCurrentMailing', value)
        },
        handlerChangeCreateMailingDialog({commit}, value) {
            commit('changeCreateMailingDialog', value)
        },
        handlerCreateMailing({state, rootState}, {selectedExcel, imageDataUrl, mailingName}) {
            state.isLoading = true
            state.users = []

            const {projectName} = rootState.reports
            const reader = new FileReader()
            reader.onload = (event) => {
                const fileContent = event.target.result
                state.socket.send(JSON.stringify({
                    action: 'create',
                    payload: {projectName, excel: fileContent, imageDataUrl, mailingName},
                }))
            }
            reader.readAsDataURL(selectedExcel)
        },
        handlerSendMessage({state}, messageId) {
            state.socket.send(JSON.stringify({
                action: 'sendMessage',
                payload: messageId,
            }))
        },
        handlerSendMessages({state}, mode) {
            state.socket.send(JSON.stringify({
                action: 'sendMessages',
                payload: {mode, mailing_id: state.currentMailing},
            }))
        },
        handlerChangeTimeout({state}, value) {
            if (!value) return
            state.socket.send(JSON.stringify({
                action: 'changeTimeout',
                payload: {mailingId: state.currentMailing, value: value},
            }))
        },
        handlerChangeInProcess({state}, value) {
            state.socket.send(JSON.stringify({
                action: 'changeInProcess',
                payload: {mailingId: state.currentMailing, value: value},
            }))
        },
    },
    mutations: {
        openSocket(state, value) {
            state.socket = value
        },
        closeSocket(state) {
            state.socket.close()
            Object.assign(state, initState)
        },
        changeCurrentMailing(state, value) {
            state.isLoading = true
            state.users = []
            if (!value) return state.currentMailing = null
            state.socket.send(JSON.stringify({
                action: 'getMailingDetails',
                payload: value,
            }))
        },
        changeCreateMailingDialog(state, value) {
            state.createMailingDialog = value
        },
        // receive from socket
        list(state, value) {
            state.mailings = value
        },
        hotUpdate(state, data) {
            const updatedUser = state.users.find(el => el.id === data.id)
            if (updatedUser) Object.keys(data).map(el => updatedUser[el] = data[el])
        },
        details(state, value) {
            state.currentMailing = value.mailingId
            state.currentMailingImage = value.image
            state.users = value.users
            state.currentMailingInProcess = value.inProcess
            state.currentMailingTimeout = value.timeout
            state.isLoading = false

            if (state.createMailingDialog) state.createMailingDialog = false
        },
        changeTimeout(state, {mailingId, value}) {
            state.mailings.find(el => el.id === mailingId).timeout = value
            if (state.currentMailing === mailingId) {
                state.currentMailingTimeout = value
                vuex.dispatch(
                    'alert/info', `Для данной рассылки таймаут изменен на: ${value} секунд`
                ).then()
            }
        },
        changeInProcess(state, {mailingId, value}) {
            state.mailings.find(el => el.id === mailingId).inProcess = value
            if (state.currentMailing === mailingId) state.currentMailingInProcess = value
        },
        error(_, {code, title}) {
            vuex.dispatch('alert/error', `${code}: ${title}`).then()
        },
    },
}
