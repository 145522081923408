<template>
  <v-list dense>
    <v-subheader>Учётная запись</v-subheader>
    <v-list-item>
      <v-list-item-title class="label-username">{{ user && user.username }}</v-list-item-title>
      <v-btn icon @click="handlerLogout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-list-item>
    <v-subheader>Навигация</v-subheader>

    <v-list-item-group
        color="primary"
    >
      <v-list-item
          v-for="item in items"
          :key="item.route"
          link
          :to="!item.isUrl ? {name: item.route} : item.url"
          :target="!item.isUrl ? '_self' : '_blank'"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "NavigationComponent",
  data() {
    return {
      items: [
        {
          text: 'Главная страница',
          icon: 'mdi-home',
          route: 'botsPage',
        },
        {
          text: 'Админка',
          icon: 'mdi-cog',
          isUrl: true,
          url: '/admin/'
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', {user: 'user'}),
  },
  methods: {
    ...mapActions('auth', ['handlerLogout'])
  },
}
</script>


<style>
.label-username {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
