<template>
  <div style="max-width: 175px">
    <v-data-table
        :headers="headers"
        :loading="!headers.length"
        :items="items"
        item-key="name"
        disable-sort
        disable-pagination
        hide-default-footer
        dense
        class="pb-4"
        :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000, -1] }"
    />
    <v-btn color="success" @click="downloadFile">
      <v-icon left>mdi-microsoft-excel</v-icon>
      Скачать
    </v-btn>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {reportsService} from "@/services";

export default {
  name: "UsersFinishedPoolsComponent",
  computed: {
    headers() {
      return [
        {text: 'id', value: 'id'},
        {text: 'finished_polls', value: 'finished_polls'},
      ]
    },
    items() {
      if (!this.headers || !this.statFinishedPolls) return []

      return this.statFinishedPolls.map((finished_polls, id) => Object({id, finished_polls}))
    },
    ...mapState('reports', ['statFinishedPolls', 'projectName', 'excludeStaff']),
  },
  methods: {
    downloadFile() {
      this.handlerChangeLoading(true)
      this.$store.dispatch('alert/info', 'Файл подготавливается')

      reportsService.getFile(`/api/reports/${this.projectName}/excel/stat_finished_polls?excludeStaff=${this.excludeStaff}`)
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = e => {
              const dummy = document.createElement('a')
              dummy.href = e.target.result
              dummy.download = `stat_finished_polls.xlsx`
              dummy.click()

              this.$store.dispatch('alert/info', 'Файл готов к скачиванию, сейчас откроется окно сохранения')
              this.handlerChangeLoading(false)
            }
          })
    },
    ...mapActions('reports', ['handlerChangeLoading']),
  }
}
</script>
